/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PhysicalAddress {
    firstName?: string | null;
    lastName?: string | null;
    name: string | null;
    address: string;
    addressMore: string | null;
    postcode: string;
    city: string;
    state: string | null;
    country: string | null;
    countryCode: string | null;
    isAutocomplete: boolean | null;
    latitude: number | null;
    longitude: number | null;
}

export type OptionalPhysicalAddress = {
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    address?: string;
    addressMore?: string | null;
    postcode?: string;
    city?: string;
    state?: string | null;
    country?: string | null;
    countryCode?: string | null;
    isAutocomplete?: boolean | null;
    latitude?: number | null;
    longitude?: number | null;
    timezone?: string | null;
    phoneNumber?: string | null;
};

export type Address = {
    uid?: string | null;
    gender: '1' | '2' | '3' | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    company: string | null;
    phone: string | null;
    inputType?: 'Capadresse' | 'Manual' | 'GoogleMap' | null;
} & PhysicalAddress;

/** Representing Problem Details in HTTP APIs */
export interface Rfc7807 {
    type: string;
    title: string;
    status: number;
    detail: string;
    'invalid-params': InvalidParams;
}

/**
 * @minItems 1
 * @uniqueItems true
 */
export type InvalidParams =
    | {
          name: string;
          reason: string;
          reasonKey?: string;
      }[]
    | null;

export interface NewsletterSubscriber {
    id?: number | null;
    email: string;
    phoneNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    salesforceId?: string | null;
    keycloakId?: string | null;
    /** @deprecated */
    sezaneLegacyId?: string | null;
    /** @deprecated */
    octobreLegacyId?: string | null;
    /** @deprecated */
    sezaneSubscriptionStatus: boolean | null;
    /** @deprecated */
    octobreSubscriptionStatus: boolean | null;
    /**
     * @deprecated
     * @format date-time
     */
    sezaneSubscriptionDate?: string | null;
    /**
     * @deprecated
     * @format date-time
     */
    octobreSubscriptionDate?: string | null;
    subscriptionLocale: string;
    subscriptions?: Notifications;
}

export type UserAddress = {
    /** @format uuid */
    uid?: string | null;
    /** For the moment, key used by the Checkout during the render of the addresses listing */
    id?: number | null;
    /**
     * A flag to know if the address is the default one for the user
     * @default false
     */
    isDefault?: boolean;
    address?: Address;
};

export type ShippingShop = {
    uid: string;
    name: string;
    description: string;
    address: PhysicalAddress;
} | null;

export type PickUpAddress = {
    pickUpId: string;
    address: PhysicalAddress;
} | null;

export type CartAddresses = {
    shipping: UserAddress;
    lastMinuteShopping: UserAddress;
    billing: UserAddress;
    shop: ShippingShop;
    pickup: PickUpAddress;
    /** @default false */
    billingAddressSameAsShipping: boolean;
} | null;

export interface FtpFactoryInput {
    /** the aws s3 bucket name in which the file is stored */
    bucket: string | null;
    /** path + file name */
    key: string | null;
    /**
     * the source of the file
     * @example "FR"
     */
    source?: string | null;
}

export type SmsSubscription = {
    brandCode?: string;
    status?: boolean | null;
};

export type PostalMailSubscription = {
    brandCode?: string;
    status?: boolean | null;
};

export type NewsletterSubscription = {
    marketingBrandCode?: string;
    status?: boolean | null;
    /** @format date-time */
    subscriptionDate?: string | null;
};

export interface ProductStatus {
    label: 'in_other_basket' | 'available_soon' | 'available' | 'unavailable' | 'preorderable';
    /** @format date-time */
    preorderDate?: string | null;
}

export type ProductPrice = {
    amount: number;
    currency: string;
} | null;

export type UserSegment = {
    brandCode?: string;
    segment?: number | null;
};

export interface TranslatableObject {
    translationKey: string;
    translationValue: string;
}

export type Notifications = {
    newsletter?: NotificationSubscription[] | null;
    sms?: ({
        /** @format date-time */
        subscriptionConfirmationDate?: string | null;
    } & NotificationSubscription)[];
    postal_mail?: NotificationSubscription[] | null;
};

export interface NotificationSubscription {
    marketingBrandCode: string;
    status: boolean | null;
    /** @format date-time */
    subscriptionDate?: string | null;
}

export interface ShoppingContext {
    brand: string;
    siteCode: string;
    currencyCode: string;
    source?: string;
    userAgent?: string | null;
    type?: string;
    countryCode?: string | null;
    regionCode?: string | null;
    locale?: string | null;
}

export interface OrderExportBody {
    /** Fields in the exported file */
    fields?: (
        | 'reference'
        | 'user.keycloakId'
        | 'user.lastName'
        | 'user.firstName'
        | 'user.email'
        | 'user.nbOrders'
        | 'context.siteCode'
        | 'context.source'
        | 'addresses.shipping.countryCode'
        | 'paymentMethod'
        | 'shipping.shippingModeName'
        | 'codeUsed'
        | 'createdAt'
        | 'paidAt'
        | 'lastFailedPaymentDate'
        | 'shipping.shippingDate'
        | 'cancelledAt'
        | 'cancelledByAdmin'
        | 'shipping.shippingTrackingCode'
        | 'purchase.subTotalPriceIncludingVat'
        | 'purchase.shippingPriceIncludingVat'
        | 'purchase.totalDiscountPrice'
        | 'purchase.totalDeclaredDutiesPaidPrice'
        | 'purchase.totalPriceVat'
        | 'purchase.totalSalesTaxPrice'
        | 'purchase.totalPriceIncludingAllTaxes'
        | 'incotermCode'
        | 'paymentStatus'
        | 'status'
        | 'returnsIds'
        | 'context.currencyCode'
        | 'retailShippingShop'
        | 'returnTrackingCode'
        | 'returnShippingMode'
        | 'preorderDate'
    )[];
    /** Filters to apply to the exported file */
    filters?: string[];
    /** Sorts to apply to the exported file */
    sorts?: string[];
}

export interface EcardExportBody {
    /** Fields in the exported file */
    fields?: (
        | 'brandCode'
        | 'code'
        | 'status'
        | 'currencyCode'
        | 'sendAt'
        | 'expiredAt'
        | 'orderReference'
        | 'buyerEmail'
        | 'receiverEmail'
        | 'initialAmount'
        | 'remainingAmount'
    )[];
    /** Filters to apply to the exported file */
    filters?: any[];
    /** Sorts to apply to the exported file */
    sorts?: string[];
}

export interface CustomerExportBody {
    /** Fields in the exported file */
    fields?: (
        | 'keycloakId'
        | 'lastName'
        | 'firstName'
        | 'email'
        | 'createdAt'
        | 'nbOrders'
        | 'lastOrderedAt'
        | 'segment'
        | 'exportableTags'
    )[];
    /** Filters to apply to the exported file */
    filters?: string[];
    /** Sorts to apply to the exported file */
    sorts?: string[];
}

export type PimVariant = {
    uuid: string;
    referenceImage: string | null;
    prices: string[] | null;
    model: PimModel;
    color: PimColor;
    selectedSize: PimSize;
    [key: string]: any;
};

export interface PimModel {
    uuid: string;
    label: string | null;
    description: string | null;
}

export interface PimColor {
    uuid: string;
    label: string | null;
}

export type PimSize = {
    uuid: string;
    value: string | null;
};

export interface ImportResponse {
    id: number | null;
    status: string;
    count: number;
    errors: string[] | null;
}

export interface ImportCreditResponse {
    id?: number | null;
    status: string;
    count?: number;
    errors?: string[] | null;
    /** @default 0 */
    countSuccess?: number;
    /** @default 0 */
    countErrors?: number;
    /**
     * @minItems 1
     * @uniqueItems true
     */
    'batch-invalid-params'?: {
        identifier: string;
        /**
         * @minItems 1
         * @uniqueItems true
         */
        'invalid-params':
            | {
                  name: string;
                  reason: string;
                  reasonKey?: string;
              }[]
            | null;
    }[];
}

export type Parameter = {
    id: string;
    value: any;
    description: string;
} | null;

export interface TagList {
    id: number;
    label: string;
    userCount: number;
    /** @format date-time */
    createdAt: string;
    isVip?: boolean;
    isLocked?: boolean;
}

export interface Tag {
    id?: number;
    label?: string;
    [key: string]: any;
}

export interface ShippingMode {
    id?: number | null;
    uid?: string | null;
    /** @default [] */
    brandCodes: string[];
    type: string | null;
    taxCode: string | null;
    sezaneLegacyId?: number | null;
    octobreLegacyId?: number | null;
    /** @default false */
    pouchFlux?: boolean;
    logisticCodePouchOverride?: string | null;
    /** @default false */
    enabled?: boolean;
    /**
     * computed, whether shippingMode is enabled or not in checkout
     * @default false
     */
    isAvailable?: boolean;
    /** @default [] */
    shippingModeAttributesGroups?: ShippingModeAttributesGroup[];
    /** Shop associated when the delivery type is "Shop" or "LMS" */
    shop?: Shop;
    /** LMS availability time slot */
    openHours?: OpenHours;
    /** Maximum daily number of orders */
    maxDeliveryQuantity?: number | null;
    maxDeliveryQuantityReached?: boolean | null;
    maxPouchFluxQuantityReached?: boolean | null;
    pouchFluxQuantityReached?: number | null;
    /** Current number of orders */
    currentDeliveryQuantity?: number | null;
    /** @default [] */
    freeforCustomerTags?: Tag[];
    translations: Record<string, ShippingModeTranslation>;
    /** @default [] */
    excludedEans?: string[];
    /** @default 3 */
    deliveryDelay?: number;
    /** @default false */
    splitDangerous?: boolean;
    splitReason?: 'flammable' | 'bulky' | 'customs' | null;
    trackingBaseUrl?: string | null;
    /** @default true */
    freeShippingFeesForRetailCarts?: boolean;
    /** @default [] */
    warehouseShippingModeOverload?: WarehouseShippingModeOverload[];
    [key: string]: any;
}

export interface ShippingModeAttributesGroup {
    id?: number | null;
    logisticCode?: string | null;
    /** @default [] */
    shippingPrices: ShippingModeAttributePrice[];
    /** @default [] */
    freeThresholdPrices: ShippingModeAttributePrice[];
    /** @default [] */
    shippingModeAttributes: ShippingModeAttribute[];
    /** @default [] */
    translations?: Record<string, ShippingModeAttributesGroupTranslation>;
    minDeliveryDelay?: number | null;
    maxDeliveryDelay?: number | null;
    [key: string]: any;
}

export interface ShippingModeAttributePrice {
    price: number;
    currencyCode: string;
    freeThresholdPrice?: number | null;
    freeThresholdDisplayPrice?: string | null;
}

export interface ShippingModeAttribute {
    countryCode: string;
    /** @default [] */
    stateCodes?: string[];
    /** @default [] */
    zipCodes?: string[];
    [key: string]: any;
}

export interface ShippingModeTranslation {
    locale: string;
    name: string;
    description: string;
}

export interface ShippingModeAttributesGroupTranslation {
    locale: string;
    description?: string | null;
    deliveryDelay?: string | null;
    emailMessage?: string | null;
}

export type Shop = {
    id?: number;
    uid: string | null;
    /** @minLength 1 */
    brandCode: string;
    /** @minLength 1,1 */
    description: string;
    /** @default false */
    enabled?: boolean;
    address: {
        firstName?: string | null;
        lastName?: string | null;
        name: string | null;
        address: string;
        addressMore: string | null;
        postcode: string;
        city: string;
        state: string | null;
        country: string | null;
        countryCode: string | null;
        isAutocomplete: boolean | null;
        latitude: number | null;
        longitude: number | null;
    };
    logisticAddress?: ShopAddress;
    phone?: string | null;
    email?: string | null;
    vatNumber?: string | null;
    siret?: string | null;
    /** @minLength 1 */
    timezone: string;
    openHours: OpenHours | null;
    defaultSiteCode: string;
    defaultCurrencyCode: string;
    defaultLocaleCode?: string;
    /** @default false */
    isReturnNotAllowed?: boolean;
    /** @default false */
    isRefundNotAllowed?: boolean;
    /** @default false */
    isInvoiceNotAllowed?: boolean;
    shopIdAdyen?: string | null;
    merchantAccountAdyen?: string | null;
    hasAppleTapToPay?: boolean | null;
    supplyWarehouses?: ShopWarehouse[] | null;
    crossDockWarehouses?: ShopWarehouse[] | null;
};

export type ShopAddress = {
    name?: string | null;
    address: string;
    addressMore?: string | null;
    postcode: string;
    city: string;
    state?: string | null;
    country?: string | null;
    countryCode: string;
    latitude?: number | null;
    longitude?: number | null;
};

export type OpenHours = {
    day1?: ShopOpenHour[] | null;
    day2?: ShopOpenHour[] | null;
    day3?: ShopOpenHour[] | null;
    day4?: ShopOpenHour[] | null;
    day5?: ShopOpenHour[] | null;
    day6?: ShopOpenHour[] | null;
    day7?: ShopOpenHour[] | null;
};

export interface ShopOpenHour {
    id?: number;
    day: number;
    startTime: string;
    endTime: string;
}

export interface ShopWarehouse {
    priority: number;
    warehouseUid: string;
}

export type CountryCurrencyMatch = {
    /** Country code iso2, for example FR, US, UK, etc */
    countryCode: string;
    /** Currency defined for the country as for example EUR, AUD, GBP, etc */
    currencyCode: string;
    /** Brand code, for example sezane */
    brandCode: string;
}[];

export type ShippingModeMissingCurrency = {
    /** Brand code, for example sezane */
    brandCode: string;
    /** Country code iso2, for example FR, US, UK, etc */
    countryCode: string;
    /** Currency defined for the country as for example EUR, AUD, GBP, etc */
    currencyCode: string;
    /** Id of the shipping mode to fix */
    shippingModeId: number;
    /** The name of the shipping mode */
    shippingModeName?: string | null;
    [key: string]: any;
}[];

export interface ShippingOrderReturnMode {
    id?: number | null;
    countryCode: string;
    stateCode?: string | null;
    zipCode?: string | null;
    transporter?: {
        id: number;
        /** The name of the transporter (read-only) */
        name?: string;
    } | null;
    orderReturnMode: {
        id: number;
        /** The name of the return mode for the given locale (read-only) */
        name?: string;
        type?: string | null;
    };
    /** Number of days necessary for the user to be refunded (for standard eligibility) */
    refundTerm: number;
    /** Number of days necessary for the user to be refunded (for eligibility light) */
    secondRefundTerm?: number | null;
}

export interface ShippingModePouchParams {
    thresholdMaxOrders: number;
    ean: string;
    productType: 'A' | 'P';
}

export interface WarehouseShippingModeOverload {
    /** Warehouse uuid */
    warehouseUid: string;
    /** Id of the shipping mode to use */
    shippingModeOverloadId: number;
    [key: string]: any;
}

export interface ShippingModeLight {
    id?: number;
    uid?: string;
    brandCodes?: string[];
    type?: string;
    translations?: Record<string, ShippingModeTranslation>;
    [key: string]: any;
}

export interface ScheduledTask {
    id?: string | null;
    type: string;
    name: string;
    schedule: string;
    enabled?: boolean;
}

export interface EmailTemplate {
    id?: number;
    name?: string;
    code?: string;
}

export interface EmailTemplateVersion {
    id?: number;
    eventId?: string;
    brandCode?: string;
    siteCode?: string;
    locale?: string;
}

export interface CreateEmailTemplateVersion {
    eventId?: string;
    brandCode?: string;
    siteCode?: string;
    locale?: string;
    requiredVariables?: string[];
    optionalVariables?: string[];
}

export interface UpdateEmailTemplateVersion {
    eventId?: string;
    brandCode?: string;
    siteCode?: string;
    locale?: string;
    requiredVariables?: string[];
    optionalVariables?: string[];
}

export interface EmailTemplateVersionList {
    template?: EmailTemplate;
    versions?: EmailTemplateVersion[];
}

export interface OrderReturnCreatePayload {
    /** @default "store_return" */
    returnType?: 'store_return' | 'refunded_early';
    /** @default true */
    refundShipping?: boolean;
    /** @default null */
    amount?: number | null;
    refundType: 'card' | 'credits' | 'cash';
    items: {
        lineItemUuid: string;
        quantity: number;
        motive?:
            | 'TOO_BIG'
            | 'TOO_SMALL'
            | 'DEFECTIVE'
            | 'NOT_MATCHING'
            | 'STYLE_DOESNT_FIT'
            | 'TOO_LONG'
            | 'TOO_SHORT'
            | null;
    }[];
    /**
     * For credits refund type, you can specify an expiration date
     * @format date
     * @default null
     */
    expiredAt?: string | null;
    /** For return type "store", saves the shop in which the admin has created the return */
    shop_id?: number | null;
    /** For credits refund type with returnType store_return, you can specify the email of the user who will receive the credits */
    userCreditBeneficiaryEmail?: string | null;
}

export interface OrderReturnUpdatePayload {
    returnedItems?: {
        /**
         * for already existing returned items, the uid of the item
         * @format uid
         */
        uid?: string | null;
        lineItemUuid?: string;
        expectedQuantity?: number;
        returnMotive?: string | null;
    }[];
}

export interface OrderReturnMode {
    id?: number | null;
    /** @format uuid */
    uid?: string | null;
    /** @default [] */
    brandCodes: string[];
    type: string;
    /** @default false */
    enabled?: boolean;
    translations: Record<string, OrderReturnModeTranslation>;
    /** @default [] */
    transporters?: OrderReturnModeTransporter[];
    trackingBaseUrl?: string | null;
    customsDutyFree?: boolean | null;
}

export interface OrderReturnModeTranslation {
    locale: string;
    name: string;
}

export interface OrderReturnModeTransporter {
    name: string;
    id?: number | null;
    trackingBaseUrl?: string | null;
}

export interface OrderReturnListElement {
    id?: number;
    /** @format uuid */
    uid?: string;
    user?: {
        keycloakId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string;
    };
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    status?: OrderReturnStatus;
    returnedItemsCount?: number;
    returnMode?: ReturnMode;
    orderReferences?: OrderReturnListReference[];
    brandCodes?: string[];
    refundMode?: 'Manual' | 'Automatic' | 'Early' | null;
    /** @default false */
    hasInvoice?: boolean;
}

export interface OrderReturnListReference {
    reference?: number;
    externalId?: string | null;
}

export interface OrderReturnDetail {
    id?: number;
    /** @format uuid */
    uid?: string;
    status?: OrderReturnStatus;
    user?: {
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        email?: string;
        keycloakId?: string | null;
    };
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    deletedAt?: string | null;
    /** @format date-time */
    transporterHandOverDate?: string | null;
    /**
     * @format date-time
     * @default null
     */
    receptionDate?: string | null;
    /** @default "ADMIN" */
    createdBy?: string;
    returnMode?: ReturnMode;
    refundOption?: 'card' | 'credits' | 'cash' | 'global-e';
    warehouse?: OrderReturnWarehouse;
    /** the shop in which the return was processed */
    shop?: {
        id?: number;
        name?: string;
    } | null;
    orders?: {
        reference?: number | null;
        externalId?: string | null;
        shippingTrackingCode?: string | null;
        shippingTrackingLink?: string | null;
        returnTrackingCode?: string | null;
        returnDhlConfirmationNumber?: string | null;
        returnTrackingLink?: string | null;
        currencyCode?: string;
        discounts?: OrderDiscount[];
        shippingPrice?: number;
        totalSalesTaxPrice?: number;
        totalDutiesPrice?: number;
        totalPrice?: number;
    }[];
    /** @default [] */
    brandCodes?: ('sezane' | 'octobre')[];
    /** @default 0 */
    expectedRefundAmount?: number;
    /** @default 0 */
    receivedRefundAmount?: number;
    returnedItems?: OrderReturnItem[];
    salesTaxRefunds?: {
        avalaraTransactionId?: string | null;
        amountRefunded?: number;
    }[];
    /**
     * List of refunds
     * @default []
     */
    refunds?: OrderRefund[];
    /**
     * List of refunds only linked to the order
     * @default []
     */
    orderRefunds?: OrderRefund[];
    /** 0 = not eligible, 1 = eligible, 2 = eligible_light */
    earlyRefundEligibility?: '0' | '1' | '2' | null;
    /** the reason why the return is not eligible for early refund (null if eligible) */
    earlyRefundIneligibilityReason?: string | null;
    /** the reason why the return transitions to "to be processed" (null if did not make the transition) */
    toBeProcessedReason?:
        | 'order_voucher_code_exclusion'
        | 'return_no_line_item'
        | 'return_customized_item'
        | 'order_commercial_refund'
        | 'order_refunds_frozen'
        | 'order_not_shipped'
        | 'other_return_refunded_early'
        | 'order_validated_too_long_ago'
        | 'return_cannot_cancel_refunds'
        | 'global_e_refund_failed'
        | 'excess_defective_returns'
        | null;
    /** @format date-time */
    minRefundDate?: string | null;
    refundMode?: 'Manual' | 'Automatic' | 'Early' | null;
    transitions?: OrderReturnTransition[];
    /** @default false */
    hasInvoice?: boolean;
    dhlTechnicalCode?: string | null;
}

export interface OrderReturnTransition {
    id?: number;
    /** Can be "customer", "system" or an User Admin uuid */
    admin?: string;
    /** @format date-time */
    date?: string;
    /** the transition name */
    transition?: string;
    /** the subjectId */
    subjectId?: number;
    /** additional info as to why the transition was applied */
    reason?: string | null;
}

export interface OrderReturnItem {
    /** @format uid */
    uid?: string;
    expectedQuantity?: number;
    receivedQuantity?: number;
    refundedQuantity?: number;
    orderedQuantity?: number;
    refundableQuantity?: number;
    returnMotive?: string | null;
    qualityCode?: string | null;
    orderLineItem?: OrderReturnItemProduct;
}

export type OrderReturnItemProduct = {
    imageUrl?: string;
    ean?: string;
    label?: string;
    /** Variant code of the product (none for ecard) */
    variantCode?: string | null;
    sizeLabel?: string;
    colorLabel?: string | null;
    unitPrice?: number;
    totalPrice?: number;
    lineItemUuid?: string | null;
    orderReference?: number | null;
    customisation?: Customisation;
};

export type OrderDiscount = {
    type?: 'credit' | 'voucher-code' | 'ecard';
    code?: string;
    amount?: number;
} | null;

export type ReturnMode = {
    /**
     * uid of the linked return mode
     * @format uuid
     */
    uid?: string;
    /** The name of the return mode, translated to the given locale */
    name?: string;
} | null;

export type OrderReturnWarehouse = {
    /** @format uuid */
    uid?: string;
    name?: string;
} | null;

export enum OrderReturnStatus {
    Pending = 'pending',
    ToBeProcessed = 'to_be_processed',
    Processed = 'processed',
    RefundedEarly = 'refunded_early',
    Canceled = 'canceled',
    InTransit = 'in_transit',
    PendingRefund = 'pending_refund',
    RefundsCanceled = 'refunds_canceled',
    RefundsFailed = 'refunds_failed',
}

export interface OrderRefund {
    id?: number;
    /** @format uuid */
    uid?: string;
    /** @format date-time */
    createdAt?: string;
    type?: 'card' | 'ecard' | 'credits' | 'cash' | 'globale' | 'adyen';
    amountRefunded?: number;
    /** @default [null,null] */
    creditId?: number | null;
    /** @default [null,null] */
    pspReference?: string | null;
    /** @default [null,null] */
    ecardId?: number | null;
    cancellable?: boolean;
    status?: 'pending' | 'processed' | 'canceled' | 'failed';
    refundHolder?: {
        type?: 'complaint' | 'return' | 'commercial_refund';
        uid?: string | null;
        /** @format date-time,date-time */
        createdAt?: string | null;
        adminUid?: string | null;
        avalaraTransactionId?: string | null;
    };
    /**
     * the date of the sent email matching the refund type (credit creation / refund notification)
     * @format date-time
     */
    createEmailSent?: string | null;
    /** @default null */
    arn?: string | null;
}

export interface ProcessOrderReturnInput {
    /** @default "warehouse" */
    returnType?: 'warehouse' | 'retail';
    /** For retail return type, saves the shop in which the admin has created the return */
    shop_id?: number | null;
    payload?: SingleOrderRefundPayload[];
}

export interface SingleOrderRefundPayload {
    orderReference?: number;
    refundType?: 'card' | 'credits' | 'cash' | null;
    /** @default false */
    refundShipping?: boolean;
    amount?: number | null;
    /** The items to refund. Used to compute the calculated refunded amount if totalAmount is not provided */
    returnItemsToRefund?: {
        /**
         * uid of the order return item (caution: not the line item uid)
         * @format uuid
         */
        uid?: string;
        /** the quantity to refund */
        quantity?: number;
        /** if set, override the item's return motive */
        returnMotive?: string | null;
    }[];
    /**
     * For credits refund type, you can specify an expiration date
     * @format date
     * @default null
     */
    expiredAt?: string | null;
    /** For credits refund type with returnType store_return, you can specify the email of the user who will receive the credits */
    userCreditBeneficiaryEmail?: string | null;
}

export interface ReturnItemsRefundedQuantity {
    /** The items to update. */
    returnItemsToUpdate?: {
        /**
         * uid of the order return item (caution: not the line item uid)
         * @format uuid
         */
        uid?: string;
        /** the quantity refunded */
        quantity?: number;
    }[];
}

export interface RoleRequestBody {
    name: string;
}

export type ListOfRoles = Role[];

export interface RightsBody {
    rights?: UuidArray;
}

export type UuidArray = string[];

export type ListOfRights = Right[];

export interface Right {
    /** @format uuid */
    uuid: string;
    description: string;
    group: string;
}

export interface Role {
    /** @format uuid */
    uuid?: string;
    name?: string;
    rights?: ListOfRights;
}

export interface TurnoverWidget {
    users?: TurnoverWidgetUsers;
    sezane?: TurnoverWidgetData;
    octobre?: TurnoverWidgetData;
}

export interface TurnoverWidgetUsers {
    customer?: number;
    newsletter?: number;
}

export interface TurnoverWidgetData {
    /** @format float */
    total_vat_included?: number;
    /** @format float */
    total_vat_excluded?: number;
    orders_count?: number;
    web_orders_count?: number;
    retail_orders_count?: number;
    /** @format float */
    average_cart?: number;
    /** @format float */
    web_average_cart?: number;
    /** @format float */
    retail_average_cart?: number;
    /**
     * @format float
     * @min 0
     * @max 1
     */
    out_of_france_proportion?: number;
    newsletter?: number;
}

export interface BestProductsWidget {
    brand_code?: 'sezane' | 'octobre';
    /** @example "S172PAN004B" */
    product_code?: string;
    /** @example "Veste Will Indigo" */
    variant_label?: string;
    /** @example "https://res.cloudinary.com/qlf/image/upload/c_fill,d_placeholder_dark.png,fl_progressive:semi,h_475,q_auto:best,w_338/v1589461125/website/product/agv37jzjehlv0oivdl7r.jpg" */
    variant_image?: string;
    /**
     * Unit price VAT included for this product
     * @format float
     */
    unit_vat_included?: number;
    /**
     * Unit price VAT excluded for this product
     * @format float
     */
    unit_vat_excluded?: number;
    /**
     * Total VAT included turnover for this product
     * @format float
     */
    total_vat_included?: number;
    /**
     * Total VAT excluded turnover for this product
     * @format float
     */
    total_vat_excluded?: number;
    /** Number of Order with this product */
    orders_count?: number;
    /** Number of sold products */
    quantity?: number;
    /** Stock disponible web */
    web_available?: number;
    /** Stock disponible warehouse */
    warehouse_available?: number;
    /** Stock disponible retail */
    retail_available?: number;
}

export interface Warehouse {
    uid?: string | null;
    name: string;
    /**
     * If the warehouse is enabled for order processing
     * @deprecated
     */
    enabledForOrders?: boolean | null;
    /** If the warehouse is enabled for transfert purchase to another warehouse. */
    enabledForSwitchPurchase: boolean;
    /** If the warehouse is enabled for return processing. */
    enabledForReturns: boolean;
    address: {
        firstName?: string | null;
        lastName?: string | null;
        name: string | null;
        address: string;
        addressMore: string | null;
        postcode: string;
        city: string;
        state: string | null;
        country: string | null;
        countryCode: string | null;
        isAutocomplete: boolean | null;
        latitude: number | null;
        longitude: number | null;
    };
    timezone: string;
    orderLimit?: number | null;
    /** @default false */
    excludeB2BOrder?: boolean;
    /** @default false */
    excludeCustomOrder?: boolean;
    protected?: boolean | null;
    /**
     * @format time
     * @example "12:45"
     */
    expiryDueTime?: string | null;
}

export interface CountrySetting {
    uid?: string | null;
    countryCode: string;
    /** @default true */
    enabled: boolean;
    countryAvailableWarehouses?: CountryAvailableWarehouse[];
    /** @default false */
    grouping: boolean;
    /** @default false */
    optimization: boolean;
    /** @default false */
    forceDefaultWarehouseForClickAndCollect?: boolean;
    /** @default false */
    forceDefaultWarehouseForPickUp?: boolean;
}

export interface CountryAvailableWarehouse {
    priority: number;
    warehouseUid: string;
}

export interface JournalCodes {
    purgeable?: Record<string, number>;
    persistant?: Record<string, number>;
}

export interface JournalCreate {
    code?: number;
    additionalInformations?: Record<string, string | number>;
}

export interface DatabaseBackupCreate {
    backupName?: string;
    /** @format date-time */
    backupDate?: string;
}

export interface CartList {
    id: number | null;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string | null;
    user: UserCartList;
    /**
     * Last update date
     * @format date-time
     */
    updatedAt: string | null;
    /**
     * Expiration date for a cart
     * @format date-time
     */
    expireAt: string | null;
    context?: ShoppingContext;
    /** @format uuid */
    uid: string | null;
    brand: string;
    siteCode: string;
    totalPrice: number;
    currencyCode?: string;
    countItems: number;
    shippingCountryCode: string | null;
    source?: string;
    userAgent?: string | null;
}

export type UserCartList = {
    email: string;
    keycloakId: string | null;
    firstName: string | null;
    lastName: string | null;
};

export type CartRead = {
    id: number;
    /**
     * Expiration date for a cart
     * @format date-time
     */
    expireAt: string | null;
    context?: ShoppingContext;
    user: UserCartRead;
    lineItems: LineItemsCartRead[];
    /** @default [] */
    giftItems?: GiftItemsCartRead[];
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string | null;
    /**
     * Last update date
     * @format date-time
     */
    updatedAt: string | null;
    /** @format uuid */
    uid: string | null;
    brand: string;
    siteCode: string;
    subTotalPrice: number;
    shippingPrice: number;
    totalVat: number | null;
    totalPrice: number;
    amountSalesTax: number;
    amountDutiesAndOtherFees: number;
    currencyCode: string | null;
    giftMessage: GiftMessage;
    countItems: number;
    addresses: {
        shipping: UserAddress;
        lastMinuteShopping: UserAddress;
        billing: UserAddress;
        shop: ShippingShop;
        pickup: PickUpAddress;
        /** @default false */
        billingAddressSameAsShipping: boolean;
    } | null;
    shippingModeName: string | null;
    /** @format uuid */
    shippingModeAttributeUid?: string | null;
    orders: OrderRead[] | null;
    source?: string;
    userAgent?: string | null;
    type?: string;
    shippingCountryCode?: string | null;
    discounts?: Discount[] | null;
    cashier?: Cashier;
    status?: string;
};

export type UserCartRead = {
    sezaneLegacyId: string | null;
    octobreLegacyId: string | null;
    firstName: string | null;
    lastName: string | null;
    fullName: string | null;
    email: string;
    keycloakId: string | null;
    salesforceId: string | null;
    phone: string | null;
    birthDate: string | null;
    gender: number | null;
};

export interface LineItemsCartRead {
    uid?: string;
    ean: string;
    label: string;
    sizeLabel: string | null;
    sizeScaleLabel?: string | null;
    colorLabel: string | null;
    imageUrl: string;
    imageUrlLarge?: string;
    productUrl: string;
    productProperty: {
        productId: number;
        hash: string | null;
        name: string;
        selectedVariant: {
            variantId: number;
            code: string;
        };
    };
    quantity: number;
    /** @default 0 */
    lastMinuteQuantity: number;
    /** @default 0 */
    simpleDeliveryQuantity: number;
    /** @format float */
    vatRate: number | null;
    unitPrice: number;
    unitPriceVat: number;
    crossedOutPrice: number | null;
    totalPrice: number;
    totalCrossedOutPrice: number | null;
    dangerous?: boolean | null;
    /** @default [] */
    packaging: PackagingLineItemCart[];
    customisation?: Customisation;
    /**
     * Whether the product can be customized
     * @default false
     */
    customisable?: boolean;
    warehouse?: string | null;
}

export interface GiftItemsCartRead {
    uid?: string;
    ean: string;
    /** Variant code of the product (none for ecard) */
    variantCode?: string | null;
    label: string;
    sizeLabel: string | null;
    colorLabel: string | null;
    imageUrl: string;
    imageUrlLarge?: string;
    productUrl: string;
    quantity: number;
    /** @default 0 */
    lastMinuteQuantity: number;
    /** @default 0 */
    simpleDeliveryQuantity: number;
    /** @format float */
    vatRate: number | null;
    unitPrice: number;
    unitPriceVat: number;
    crossedOutPrice: number | null;
    totalPrice: number;
    totalCrossedOutPrice: number | null;
    dangerous?: boolean | null;
    /** @default [] */
    packaging?: PackagingLineItemCart[];
    productId?: number | null;
    customisation?: Customisation;
    commercialOperation?: {
        id?: number | null;
        /** @format uuid */
        uid?: string | null;
        /** Nom de l'opération commerciale */
        name?: string;
    };
}

export interface PackagingLineItemCart {
    ean: string;
    title: string;
    selected: string;
    selectable: string;
    imageUrl: string | null;
    warehouse?: string | null;
}

export type GiftMessage = {
    title: string | null;
    message: string | null;
};

export type Cashier = {
    keycloakId?: string | null;
    fullName?: string | null;
    email?: string | null;
};

export interface CartCommercialOperation {
    uid?: string;
    name?: string;
    types?: CommercialOperationType[];
}

export interface MassImportCreateCartsBody {
    /** @format binary */
    cart_import: File;
    /** Uuid of the shipping mode attribute */
    shippingModeAttributeUid: string;
    /** Type of the cart */
    type: MassImportCartType;
    /** @default false */
    sendMail: boolean;
    /** @default null */
    stateCode?: string | null;
    brandCode: string;
}

/**
 * Type of the cart
 * @default "customer_service"
 */
export enum MassImportCartType {
    CustomerService = 'customer_service',
    Influence = 'influence',
    Logistic = 'logistic',
    Marketing = 'marketing',
    Payable = 'payable',
}

export interface CartMassImportRead {
    id?: number;
    originalFileName?: string;
    status?: 'pending' | 'in_progress' | 'done' | 'error';
    createdOrdersStatus?: 'pending' | 'in_progress' | 'done' | null;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    startedAt?: string | null;
    /** @format date-time */
    finishedAt?: string | null;
    brandCode?: string;
    siteCode?: string;
    shippingModeAttributeUid?: string;
    stateCode?: string | null;
    localeCode?: string;
    currencyCode?: string;
    sendMails?: boolean;
    /** Type of the cart */
    type?: MassImportCartType;
    errorMessage?: string | null;
    carts?: CartRead[];
    expiredCarts?: boolean;
    canCreateOrders?: boolean;
}

export interface CustomisationDetails {
    /** Title for the popin */
    name?: string | null;
    /** Description for the popin */
    description?: string | null;
    /** Image */
    mediaUrl?: string | null;
    /**
     * Authorized positions
     * @example ["heart","right"]
     */
    positions?: (string | null)[];
    /**
     * Type of customisation
     * @example "embroidery"
     */
    type?: string | null;
    /**
     * Format of the customisation
     * @example "text"
     */
    format?: string | null;
    authorizedCharactersPattern?: string | null;
    authorizedCharacters?: (string | null)[];
    whitelistedCharacters?: (string | null)[];
    /** Authorized lines of text */
    rows?: {
        /** Maximum number of characters allowed for this line */
        limit?: number | null;
        /** Value provided for this line by the user */
        value?: string | null;
    }[];
    /**
     * Authorized colors
     * @example [{"label":"1 - Bleu clair","value":"rgb(60 92 128)"},{"label":"8 - Bleu électrique","value":"rgb(18 57 102)"}]
     */
    colors?: {
        label?: string | null;
        value?: string | null;
    }[];
    /**
     * Authorized symbols
     * @example ["♥"]
     */
    symbols?: (string | null)[];
    /** A customisation applied on an item */
    itemCustomisation?: {
        rows?: string[] | null;
        color?: string | null;
        position?: string | null;
        type?: string | null;
        format?: string | null;
    };
}

export interface WalletApplePushRequest {
    brandCode?: string | null;
    userKeycloakId?: string | null;
    message?: string | null;
    walletUpdate?: boolean;
}

export type UserRead = {
    id: number;
    keycloakId: string;
    salesforceId: string | null;
    email: string;
    enabled: boolean;
    firstName: string | null;
    lastName: string | null;
    otherLastName: string | null;
    phone: string | null;
    /** @format date-time */
    birthDate: string | null;
    gender: number | null;
    sezaneLegacyId: string | null;
    octobreLegacyId: string | null;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
    /** @format date-time */
    deletedAt: string | null;
    fullName: string | null;
    nbOrders: number | null;
    /** @min 0 */
    nbWebOrders?: number | null;
    /** @min 0 */
    nbRetailOrders?: number | null;
    /** @format date-time */
    lastOrderDate?: string | null;
    /** @default [] */
    tags: Tag[];
    countryCode: string | null;
    locale: string | null;
    /** @deprecated */
    segment?: number | null;
    newsletterSubscriber?: {
        /** @deprecated */
        sezaneSubscriptionStatus?: boolean | null;
        /** @deprecated */
        octobreSubscriptionStatus?: boolean | null;
        /** @deprecated */
        petitSezaneSubscriptionStatus?: boolean | null;
        /** @deprecated */
        lesComposantesSubscriptionStatus?: boolean | null;
    };
    source: 'retail' | 'web' | 'app-ios' | 'app-android' | null;
    sourceBrandCode: string | null;
    sourceSiteCode: string | null;
    sourceShop: Shop | null;
    /** @format date-time */
    createEmailSent?: string | null;
    /** @format date-time */
    createRetailEmailSent?: string | null;
    useAppleLogin?: boolean | null;
    useFacebookLogin?: boolean | null;
    useGoogleLogin?: boolean | null;
    idClientBarcodePath?: string | null;
    keycloakUuidQRCodePath?: string | null;
    userSegments?: {
        brandCode?: string;
        segment?: number | null;
    }[];
    /**
     * User return rate (0.0 to 1.0), nullable
     * @format float
     */
    returnRate?: number | null;
};

export interface UserWrite {
    email: string;
    gender: number | null;
    firstName: string | null;
    lastName: string | null;
    /** @format date-time */
    birthDate: string | null;
    tags: Tag[];
    countryCode: string | null;
    locale: string | null;
    phone: string | null;
}

export interface UserList {
    id: number;
    keycloakId: string;
    email: string;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
    fullName: string;
    nbOrders: number;
    /**
     * User last order date
     * @format date-time
     */
    lastOrderedAt: string | null;
    tags: Tag[];
    /** @deprecated */
    segment?: number | null;
    source: 'retail' | 'web' | 'app-ios' | 'app-android' | null;
    userSegments?: {
        brandCode?: string;
        segment?: number | null;
    }[];
}

export interface UserCredentials {
    keycloakId: string;
    plainPassword: string;
}

export type OrderTransitions = OrderTransition[];

export interface OrderTransition {
    id?: number;
    /** Can be "customer", "system" or an User Admin uuid */
    admin?: string;
    /** @format date-time */
    date?: string;
    /** the transition name */
    transition?: string;
    /** (deprecated, use subjectId) the order reference */
    reference?: number;
    /** the subjectId */
    subjectId?: number;
}

export interface OrderList {
    reference: number;
    /** External id from international service (globalE ; only for Order) */
    externalId: string | null;
    context: ShoppingContext;
    isFirst: boolean;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
    /** order status */
    status:
        | 'created'
        | 'pending'
        | 'to_validate'
        | 'validated'
        | 'sent_to_logistic'
        | 'integrated'
        | 'in_preparation'
        | 'packed'
        | 'shipped'
        | 'canceled'
        | 'delivered'
        | 'payment_failed'
        | 'delivered_to_customer';
    /** Payment status */
    paymentStatus?: 'waiting' | 'failed' | 'canceled' | 'validated' | 'chargeback' | null;
    paymentMethod: string | null;
    pspReference: string | null;
    addresses?: {
        shipping: {
            uid?: string | null;
            gender: '1' | '2' | '3' | null;
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            company: string | null;
            phone: string | null;
            inputType?: 'Capadresse' | 'Manual' | 'GoogleMap' | null;
        } & PhysicalAddress;
        billing: {
            uid?: string | null;
            gender: '1' | '2' | '3' | null;
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            company: string | null;
            phone: string | null;
            inputType?: 'Capadresse' | 'Manual' | 'GoogleMap' | null;
        } & PhysicalAddress;
    };
    user: UserRead;
    purchase: {
        totalPriceIncludingAllTaxes: number;
        shippingPriceIncludingVat: number;
        /** price with currency */
        shippingPriceIncludingVatDisplayPrice?: string;
    };
    shipping: Shipping;
    orderValidation?: OrderValidation | null;
    /** @default [] */
    manuallyMergeableOrders: OrderList[];
    shoppingShop?: OrderShop | null;
    isPreOrder?: boolean;
    /** @default [] */
    orderReturnIds?: any[];
    /** The status of the return linked to the order that requires the most attention by customer service */
    afterSalesStatus?: string | null;
    lineItems?: OrderDetailLms[] | null;
}

export interface OrderRead {
    reference: number;
    /** External id from international service (globalE ; only for Order) */
    externalId: string | null;
    context: ShoppingContext;
    isFirst?: boolean;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
    user: UserRead;
    orderValidation?: OrderValidation | null;
    shippingPriceIsRefunded?: boolean;
    giftMessage?: {
        title?: string | null;
        message?: string | null;
    };
    paymentTransactions?: PaymentTransactions;
    warehouse?: string | null;
    purchase: {
        subTotalPriceIncludingVat: number;
        shippingPriceIncludingVat: number;
        totalPriceVat: number;
        totalPriceIncludingAllTaxes: number;
        totalSalesTaxPrice: number;
        totalDeclaredDutiesPaidPrice: number;
    };
    lineItems: {
        uid?: string;
        ean: string;
        /** Variant code of the product (none for ecard) */
        variantCode?: string | null;
        label: string;
        sizeLabel: string;
        sizeScaleLabel?: string | null;
        colorLabel: string | null;
        imageUrl: string;
        imageUrlLarge?: string;
        productUrl: string;
        quantity: number;
        /** @default 0 */
        lastMinuteQuantity: number;
        /** @default 0 */
        simpleDeliveryQuantity: number;
        unitPrice: number;
        crossedOutPrice: number | null;
        totalPrice: number;
        totalCrossedOutPrice: number | null;
        archives: boolean;
        dangerous?: boolean | null;
        packaging?: PackagingLineItemOrder[];
        productId?: number | null;
        customisation?: Customisation;
        ecard?: {
            id: number;
            /** @format date-time */
            sendAt: string | null;
            /** @format date-time */
            sentAt?: string | null;
            receiverEmail: string;
        };
        isPreOrder?: boolean;
        /** @format date-time */
        preorderDate?: string | null;
        quantityRefunded?: number | null;
        /** quantity declared on the return form */
        quantityExpected?: number | null;
        /** actual quantity received for the return */
        quantityReceived?: number | null;
        refundableQuantity?: number;
        /** Stock Web available */
        stockWebAvailable?: number | null;
        /** Stock KN available (= ProjectedInWarehouse - (WebPurchased + WebAvailable) */
        stockKnAvailable?: number | null;
        warehouse?: string | null;
    }[];
    /** @default [] */
    giftLines?: {
        uid?: string;
        ean: string;
        /** Variant code of the product (none for ecard) */
        variantCode?: string | null;
        label: string;
        sizeLabel: string;
        colorLabel: string | null;
        imageUrl: string;
        imageUrlLarge?: string;
        productUrl: string;
        quantity: number;
        /** @default 0 */
        lastMinuteQuantity: number;
        /** @default 0 */
        simpleDeliveryQuantity: number;
        unitPrice: number;
        crossedOutPrice: number | null;
        totalPrice: number;
        totalCrossedOutPrice: number | null;
        archives: boolean;
        dangerous?: boolean | null;
        packaging?: PackagingLineItemOrder[];
        productId?: number | null;
        customisation?: Customisation;
        ecard?: {
            id: number;
            /** @format date-time */
            sendAt: string | null;
            receiverEmail: string;
        };
        isPreOrder?: boolean;
        /** @format date-time */
        preorderDate?: string | null;
        commercialOperation?: {
            id?: number | null;
            /** @format uuid */
            uid?: string | null;
            /** Nom de l'opération commerciale */
            name?: string;
        };
        warehouse?: string | null;
    }[];
    /** @deprecated */
    interactionStudioSegment?: string[] | null;
    /**
     * @deprecated
     * @format date-time
     */
    interactionStudioSegmentRetrieved?: string | null;
    /** order status */
    status:
        | 'created'
        | 'pending'
        | 'to_validate'
        | 'validated'
        | 'sent_to_logistic'
        | 'integrated'
        | 'in_preparation'
        | 'packed'
        | 'shipped'
        | 'canceled'
        | 'delivered'
        | 'payment_failed'
        | 'delivered_to_customer';
    /** Payment status */
    paymentStatus?: 'waiting' | 'failed' | 'canceled' | 'validated' | 'chargeback' | null;
    paymentMethod: string | null;
    pspReference: string | null;
    paymentReason?: string | null;
    paymentEventDate?: string | null;
    addresses: {
        shipping: {
            uid?: string | null;
            gender: '1' | '2' | '3' | null;
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            company: string | null;
            phone: string | null;
            inputType?: 'Capadresse' | 'Manual' | 'GoogleMap' | null;
        } & PhysicalAddress;
        billing: {
            uid?: string | null;
            gender: '1' | '2' | '3' | null;
            firstName: string | null;
            lastName: string | null;
            fullName: string | null;
            company: string | null;
            phone: string | null;
            inputType?: 'Capadresse' | 'Manual' | 'GoogleMap' | null;
        } & PhysicalAddress;
    };
    shipping: Shipping;
    siblingRank: number | null;
    /** @default [] */
    siblings?: OrderRead[];
    /** @default [] */
    manuallyMergeableOrders: OrderRead[];
    /** @default [] */
    mergedOrders?: OrderRead[];
    shopSpots: ShopSpot[];
    /** The order's unique Avalara transaction ID (value set after successful payment) */
    avalaraTransactionId?: string | null;
    cart: CartRead | null;
    discounts?: Discount[];
    shoppingShop?: OrderShop | null;
    hasRefundFreeze?: boolean;
    refundFreezeDate?: string | null;
    refundFreezeBy?: string | null;
    refunds?: OrderRefund[];
    complaints?: {
        id?: number;
        uuid?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        totalRefundAmount?: number;
        refundTypes?: string;
        salesTaxRefunds?: {
            avalaraTransactionId?: string;
            amountRefunded?: number;
        }[];
        lineItems?: {
            lineItemUid?: string;
            product?: {
                name?: string | null;
                variantName?: string | null;
                sizeLabel?: string | null;
                price?: number;
                currency?: string;
                productCode?: string | null;
                ean?: string;
            };
            quantity?: number;
            reason?: number | null;
            ecard?: {
                /** @format date-time */
                sendAt?: string;
                /** @format date-time */
                sentAt?: string | null;
            };
        }[];
        /**
         * For credit, you can specify an expiration date
         * @format date
         * @default null
         */
        expiredAt?: string | null;
        status?: 'processed' | 'canceled' | 'refunds_failed';
    }[];
    commercialRefunds?: CommercialRefund[];
    /** @default false */
    hasInvoice?: boolean;
    /** @default false */
    hasLogInvoice?: boolean;
    /** @default false */
    hasClientInvoice?: boolean;
    cashier?: Cashier;
    isPreOrder?: boolean;
    /** @format date-time */
    paymentExpiredEmailSent?: string | null;
    /** @format date-time */
    cancelledEmailSent?: string | null;
    /** @format date-time */
    mergedEmailSent?: string | null;
    /** @format date-time */
    deliveredToShopEmailSent?: string | null;
    /** @format date-time */
    shippedEmailSent?: string | null;
    /** @format date-time */
    paidWebEmailSent?: string | null;
    /** @format date-time */
    retailCreatedEmailSent?: string | null;
    /** @format date-time */
    preorderEmailSent?: string | null;
    /** @format date-time */
    inPreparationEmailSent?: string | null;
    /** @format date-time */
    refundEmailSent?: string | null;
    /** @format date-time */
    refundShippingFeesSent?: string | null;
    /** The status of the return linked to the order that requires the most attention by customer service */
    afterSalesStatus?: string | null;
    incotermCode?: string | null;
    orderReferenceBarcodePath?: string | null;
    parentOrderReference?: number | null;
    childrenOrdersReferences?: number[] | null;
    /** @format date-time */
    mergedAt?: string | null;
    numberOfPendingReturns?: number;
    /**
     * The date the order moves to the logistics status: 'received'
     * @format date-time
     */
    receivedDate?: string | null;
    /**
     * The date the order moves to 'delivered_to_customer' status
     * @format date-time
     */
    deliveredToCustomerDate?: string | null;
    /**
     * Indicates if the order has a return label
     * @default false
     */
    hasReturnLabel?: boolean;
    returnRateTag?: Tag | null;
}

export type Shipping = {
    shippingModeName: string | null;
    shippingModeType: string | null;
    shippingTrackingCode?: string | null;
    shippingTrackingLink?: string | null;
    shippingTrackingBaseUrl?: string | null;
    shippingReturnTrackingLink?: string | null;
    shippingReturnTrackingCode?: string | null;
    /** @format date-time */
    customerReceptionDate?: string | null;
    /** @format date-time */
    pickUpReceptionDate?: string | null;
    freeShippingReason?:
        | 'VOUCHER_CAMPAIGN_FREE_SHIPPING'
        | 'COMMERCIAL_OPERATION_FREE_SHIPPING'
        | 'FREE_CART_THRESHOLD_EXCEEDED'
        | 'CUSTOMER_TAG_FREE_SHIPPING'
        | 'SHIPPING_MODE_PRICE_ZERO'
        | 'FREE_SHIPPING_FOR_RETAIL'
        | null;
};

export type OrderValidation = {
    suspicious?: boolean | null;
    invalidAddress?: string[] | null;
    manuallyMergeable?: boolean;
};

export interface PackagingLineItemOrder {
    ean: string;
    title: string;
    imageUrl: string | null;
    warehouse?: string | null;
}

export interface ShopSpot {
    name: string;
    active: boolean;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
}

export type Discount = {
    type: 'credit' | 'voucher-code' | 'ecard';
    code: string;
    amount: number;
    voucherCampaign?: {
        id: number;
        name: string;
    } | null;
    credit?: {
        id: number;
        /** if the credit is active and with a remaining amount */
        active?: boolean;
    } | null;
};

export type Customisation = {
    color?: string | null;
    position?: string | null;
    type?: 'engraving' | 'eau_sezane' | 'embroidery' | 'hot_stamping' | 'flocking' | 'other_service';
    rows?: string[] | null;
};

export type OrderShop = {
    uid?: string | null;
    name?: string | null;
};

export type PaymentTransactions = PaymentTransaction[];

export interface PaymentTransaction {
    amount?: number | null;
    currency?: string | null;
    pspReference?: string | null;
    merchantAccountCode?: string | null;
    eventCode?: string | null;
    /** @format date-time */
    eventDate?: string | null;
    paymentMethod?: string | null;
    reason?: string | null;
    success?: boolean | null;
    processed: boolean;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt?: string | null;
}

export interface OrderCommercialRefundRequest {
    /** Selected reason id */
    reason: number;
    /** @format double */
    amount: number;
    /** Type de remboursement (carte ou avoir) */
    refundType: 'card' | 'credits';
    /** Liste des uid of the line items to link to the commercial refund */
    lineItems?: string[] | null;
    /** Si remboursement par avoir, marque pour laquelle l'avoir doit être créé */
    brand?: string | null;
    /**
     * Si remboursement par avoir, email du bénéficiaire (peut être différent de celui de la commande)
     * @format email
     */
    email?: string | null;
    /**
     * Si remboursement par avoir, date d'expiration (facultatif, sera 1 an par défaut)
     * @format date
     * @default null
     */
    expiredAt?: string | null;
    /** Commentaire */
    comments?: string | null;
}

export interface OrderRefundBody {
    /** @default true */
    refundShipping?: boolean;
    /** @default null */
    amount?: number | null;
    type?: 'psp' | 'credit';
    returnType?: 'store_return' | 'refunded_early' | 'retail' | 'warehouse' | null;
    /** @default true */
    applyHoldAmount?: boolean;
    items?: {
        lineItemUuid?: string;
        quantity: number;
        reason?: number | null;
    }[];
    /**
     * For credit refund type, you can specify an expiration date
     * @format date
     * @default null
     */
    expiredAt?: string | null;
}

export interface OrderRefundAmount {
    /** total amount to refund */
    totalAmount: number;
    /** shipping amount to refund */
    pspAmount: number;
    /** shipping amount to refund */
    ecardAmount: number;
    /** shipping amount to refund */
    creditAmount: number;
    /** cash amount to refund */
    cashAmount: number;
    /** amount to hold from the refund */
    refundHoldAmount?: number | null;
}

export interface CommercialRefund {
    id?: number | null;
    type?: 'card' | 'ecard' | 'credits';
    amountRefunded?: number;
    /** @default null */
    creditId?: number | null;
    orderCommercialRefund?: {
        lineItems?: {
            id?: number;
            uid?: string;
            label?: string;
            sizeLabel?: string | null;
            colorLabel?: string | null;
        }[];
        reason?: string;
    };
}

export interface OrderDetailLms {
    uid?: string;
    ean?: string;
    /** Variant code of the product (none for ecard) */
    variantCode?: string | null;
    label?: string;
    sizeLabel?: string;
    colorLabel?: string | null;
    imageUrl?: string;
    quantity?: number;
    /** @default 0 */
    lastMinuteQuantity?: number;
    /** @default 0 */
    simpleDeliveryQuantity?: number;
    unitPrice?: number;
    totalPrice?: number;
}

export interface OrderUpdateTrackingRequest {
    shippingTrackingCode?: string;
    returnTrackingCode?: string | null;
    /** @default false */
    sendEmail?: boolean;
}

export interface OrderCommercialOperation {
    uid?: string;
    name?: string;
    types?: CommercialOperationType[];
}

export interface CommercialRefundReason {
    id?: number;
    name?: string;
    refundTypes?: ('card' | 'credits')[];
    /** The amount hat will be suggested in the front, in dkk or other currency. Null if no suggestion. */
    defaultRefundAmount?: {
        dkk?: number | null;
        other?: number | null;
    };
}

export interface MassImportResult {
    /** Number of orders created */
    total?: number;
    /**
     * Warning messages
     * @default []
     */
    warning?: string[];
}

export interface UserComment {
    id?: number;
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    /** @default "" */
    comment: string;
    adminKeycloakId?: string | null;
    adminEmail?: string | null;
    user?: UserRead;
}

export interface VoucherCodeBody {
    id: number;
}

export interface VoucherCodeUser {
    voucherCode?: VoucherCodeBody;
    user?: UserRead;
}

export interface UserCredit {
    id?: number;
    /** @format uuid */
    uid?: string | null;
    brandCode: string;
    user?: UserRead | null;
    createdFromOrderId?: number | null;
    /** @default "" */
    code?: string;
    /** @default "" */
    obfuscatedCode?: string;
    /** @max 300000 */
    amount: number;
    remainingAmount?: number;
    currencyCode?: string | null;
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    /**
     * Expiration date
     * @format date-time
     */
    expiredAt?: string | null;
    usedOnNbOrders?: number;
    enabled?: boolean;
    userAdminKeycloakId?: string;
    createdFromSystem?: boolean;
    creationReason:
        | 'commercial_gesture'
        | 'refund'
        | 'return'
        | 'influence'
        | 'gift_credit'
        | 'employees'
        | 'competition_game'
        | 'replacement_expired_credit'
        | 'conversion_gift_card_currency'
        | 'gift_card_invalid';
}

export interface PackagingWrite {
    id?: number;
    ean: string;
    type?: string;
    opecode: number | null;
    enabled: boolean;
    selectable: boolean;
    brands: PackagingBrand[];
    sites: PackagingSite[];
    title?: string;
    translations: Record<string, PackagingTranslation>;
}

export interface PackagingList {
    id: number;
    ean: string;
    type?: string;
    opecode: number | null;
    productName: string | null;
    title?: string;
    variantLabel: string | null;
    enabled: boolean;
    selectable: boolean;
    countItems: number;
    countFailedItems?: number | null;
    countProducts: number;
    brands: PackagingBrand[];
    sites: PackagingSite[];
    translations: Record<string, PackagingTranslation>;
}

export interface Packaging {
    id: number;
    ean: string;
    type?: string;
    opecode: number | null;
    variant: PimVariant;
    enabled: boolean;
    selectable: boolean;
    brands: PackagingBrand[];
    sites: PackagingSite[];
    translations: Record<string, PackagingTranslation>;
    title?: string;
    countItems?: number | null;
    countFailedItems?: number | null;
    failedItems?: PackagingFailedItem[];
}

export interface PackagingBrand {
    brandCode: string;
}

export interface PackagingSite {
    siteId: number;
    siteCode: string;
}

export interface PackagingTranslation {
    title: string;
    locale: string;
}

export interface PackagingLineItem {
    ean: string;
    title: string;
    selected: string;
    selectable: string;
    imageUrl: string | null;
    imageUrlLarge?: string | null;
}

export interface PackagingProduct {
    variant: PimVariant;
    packagingItems: PackagingItem[];
}

export interface PackagingItem {
    ean: string;
    selected: boolean;
    size: PimSize;
}

export interface PackagingFailedItem {
    ean: string;
}

export interface SalesTax {
    id: number;
    /** Country name */
    countryName?: string;
    /** Alpha-2 country code */
    countryCode: string;
    /** Country state code */
    stateCode: string | null;
    /** Sezane brand sales tax is activated/disabled */
    salesTaxSezane: boolean;
    /** Octobre brand sales tax is activated/disabled */
    salesTaxOctobre: boolean;
    /** Sezane incoterm type, values should be between 'dap' or 'ddp' */
    incotermTypeSezane: string;
    /** Octobre incoterm type, values should be between 'dap' or 'ddp' */
    incotermTypeOctobre: string;
    /** Minimum Sezane order value to apply sales tax */
    minThresholdSezane?: number | null;
    /** Minimum Octobre order value to apply sales tax */
    minThresholdOctobre?: number | null;
}

export interface StockAlertWrite {
    ean: string;
}

export interface StockAlertList {
    id: number;
    ean: string;
    nbUserAlerts: number;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
    /**
     * Update date
     * @format date-time
     */
    updatedAt: string;
    /**
     * Send date
     * @format date-time
     */
    emailPlannedAt: string;
    siteCode: string;
    alertProduct: StockAlertProduct;
}

export interface StockAlertProduct {
    id: number | null;
    name: string;
    color: string;
    code: string;
    imageURL: string;
    legacyCode: string | null;
    ean: string;
    sizeLabel: string;
    stockLogistic: number;
    stockWeb: number;
    productId: number;
    variantId: number;
}

export interface Vat {
    id: number;
    countryCode: string;
    stateCode: string | null;
    /** @example 20 */
    standardVatRate: number;
    /** @example 5 */
    reducedVatRate: number;
    /** @default false */
    exonerated: boolean;
    /** @default false */
    defaultForCountry: boolean;
}

export interface EcardWrite {
    id: number;
    enabled: boolean;
    toGenerate?: boolean;
    /**
     * Expiration date
     * @format date-time
     * @default null
     */
    expiredAt: string | null;
    /**
     * Sending date
     * @format date-time
     */
    sendAt: string;
    receiverEmail: string;
}

export type EcardRead = {
    code?: string | null;
    obfuscatedCode: string | null;
    status: string;
    toGenerate?: boolean;
    currencyCode: string;
    brandCode: string;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
    /**
     * Sending date
     * @format date-time
     */
    sendAt: string;
    purchaseOrder: EcardOrder;
    orders: EcardOrder[] | null;
    receiverFirstName: string;
    receiverLastName: string;
    receiverEmail: string;
    amount: number;
    remainingAmount: number;
} & EcardWrite;

export type EcardOrder = {
    reference: number;
    /**
     * Creation date
     * @format date-time
     */
    createdAt: string;
    user: EcardUser;
} | null;

export type EcardUser = {
    keycloakId: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
};

export interface VoucherCampaign {
    id: number;
    name: string;
    /**
     * sources where voucher campaign is available
     * @default ["ios","android","retail","web"]
     */
    sources?: ('ios' | 'android' | 'retail' | 'web')[];
    recipientType: 'clients' | 'employees' | 'influence' | 'friends_family' | 'retail';
    enabled: boolean;
    /** @format date-time */
    startAt: string | null;
    /** @format date-time */
    endAt: string | null;
    /** @format date-time */
    createdAt: string | null;
    /** @format date-time */
    updatedAt?: string | null;
    /** @format date-time */
    deletedAt?: string | null;
    discountType: 'amount' | 'percent';
    discountPercentage?: number | null;
    freeShipping?: boolean | null;
    voucherCodeFormat: 'automatic_generation' | 'fixed_root' | 'manual_entry';
    enabledForFirstOrderOnly: boolean;
    enabledForArchives: boolean;
    combinable: boolean;
    codeUsageLimit?: number | null;
    codeUsageLimitByUser?: number | null;
    codePrefix?: string | null;
    voucherCodesQuantity?: number | null;
    voucherCodesQuantityGenerated?: number | null;
    brands: Brand[];
    sites: Site[];
    currencies: Currency[];
    voucherCodes?: VoucherCode[] | null;
    userTags?: Tag[] | null;
    allowedVariants?: Variant[] | null;
    minAllowedVariantsCount?: number | null;
    excludedVariants?: Variant[] | null;
    shops?: Shop[] | null;
    /** @default false */
    excludedFromAutomaticRefund?: boolean;
    /** @default true */
    allowAmountPartialUse?: boolean;
    /** @default false */
    isDeletable?: boolean;
    userAdminKeycloakId?: string | null;
    /** Type of campaign. For example to define that a campaign is a campaign for customer service cart */
    type?: string | null;
    usageLimitAmount?: number | null;
    adminUuid?: string | null;
    usageTotalCount?: number | null;
}

export interface Brand {
    brandCode: string;
}

export interface Site {
    /**
     * id "cms" du site
     * @example "1 pour sezane, 5 pour octobre"
     */
    siteId: number;
    /**
     * code du site
     * @example "fr"
     */
    siteCode: string;
    /**
     * Id externe lorsque l'opéco existe
     * @format uuid
     */
    uid?: string | null;
    /** marque associé */
    brandCode: string;
}

export interface Currency {
    currencyCode: string;
    discountAmount?: number | null;
    /** Montant minimum par devise */
    orderMinAmount?: number | null;
    orderMaxAmount?: number | null;
    /** @format uuid */
    uid?: string | null;
    /**
     * Montant minimum à partir duquel on doit comptabiliser le nombre de commandes
     * @default null
     */
    nthOrderMinAmount?: number | null;
}

export interface VoucherCode {
    id: number;
    prefix?: string | null;
    code?: string | null;
    enabled: boolean;
    available?: boolean;
    /** @format date-time */
    startAt?: string | null;
    /** @format date-time */
    endAt?: string | null;
    obfuscatedCode?: string | null;
    usageTotalCount?: number | null;
    usageTotalProgression?: string | null;
    voucherCodeDiscountsGenerated?: VoucherCodeDiscountGenerated[] | null;
    /** only for code from campaign with one code per user */
    user?: UserRead;
    /** only for code from campaign with one code per user and codeUsageLimitByUser set */
    usageCountPerUser?: string | null;
    /** only for code from campaign with one code per user and usageLimitAmount set */
    usageAmountPerUser?: string | null;
    userAdmin?: string | null;
}

export interface VoucherCodeDiscountGenerated {
    currencyCode: string;
    discountAmountGenerated: number;
}

export interface Variant {
    variantCode: string;
}

export interface CartPaymentSessionRequest {
    /** The adyen setup token */
    setupToken: string;
}

export interface CartPaymentSessionResponse {
    /** app session id */
    id?: string;
    /** adyen installation id */
    installationId?: string;
    /** adyen merchant account */
    merchantAccount?: string;
    /** adyen store id */
    store?: string;
    /** adyen sdk data blob */
    sdkData?: string;
}

export interface ShopDailyStatistics {
    orders_count?: number;
    guest_orders_count?: number;
    created_users_count?: number;
    retail_orders_count?: number;
    web_orders_count?: number;
    products_count?: number;
    /** @format float */
    vat_included?: number;
    /** @format float */
    vat_excluded?: number;
    /** @format float */
    one_week_before_vat_excluded?: number;
    /** @format float */
    one_year_before_vat_excluded?: number;
    /** @format float */
    retail_vat_included?: number;
    /** @format float */
    retail_vat_excluded?: number;
    /** @format float */
    retail_cash_vat_included?: number;
    /** @format float */
    retail_not_cash_vat_included?: number;
    /** @format float */
    web_vat_included?: number;
    /** @format float */
    web_vat_excluded?: number;
    /** @format float */
    web_cash_vat_included?: number;
    /** @format float */
    web_not_cash_vat_included?: number;
    delivered_to_distribute?: number;
}

export type AddProductRequestBody = {
    /**
     * Indicates if the product is from the shop but not from the web site
     * @default false
     */
    retail?: boolean;
    /** A customisation applied on an item */
    customisation?: {
        rows?: string[] | null;
        color?: string | null;
        position?: string | null;
        type?: string | null;
        format?: string | null;
    };
    /** List of ean to add to the cart */
    eans?: string[];
    /** Multi product uid */
    multiProductUid?: string | null;
};

/** Send payload according to the shipping type selected: a user or pickUp address. None when electronical or store is chosen */
export type AddShippingRequestBody = {
    userAddress?: {
        /** @format uuid */
        uid?: string | null;
        /** For the moment, key used by the Checkout during the render of the addresses listing */
        id?: number | null;
        /**
         * A flag to know if the address is the default one for the user
         * @default false
         */
        isDefault?: boolean;
        address?: Address;
    };
    pickUpAddress?: {
        pickUpId: string;
        address: PhysicalAddress;
    } | null;
};

export interface RetailUserRead {
    id?: number;
    /** @format uuid */
    keycloakId?: string;
    salesforceId?: string | null;
    email?: string;
    secondEmail?: string | null;
    enabled?: boolean;
    firstName?: string | null;
    lastName?: string | null;
    otherLastName?: string | null;
    phone?: string | null;
    /** @format date-time */
    birthDate?: string | null;
    gender?: number | null;
    sezaneLegacyId?: string | null;
    octobreLegacyId?: string | null;
    /**
     * Creation date
     * @format date-time
     */
    createdAt?: string;
    /** @format date-time */
    deletedAt?: string | null;
    fullName?: string | null;
    nbOrders?: number | null;
    /** @min 0 */
    nbOrdersWeb?: number | null;
    /** @min 0 */
    nbOrdersRetail?: number | null;
    /** @format date-time */
    lastOrderDate?: string | null;
    /** @default [] */
    tags?: Tag[];
    countryCode?: string | null;
    locale?: string | null;
    /** @deprecated */
    segment?: number | null;
    subscriptionStatus?: boolean | null;
    /** @default [] */
    userAddresses?: {
        /** @format uuid */
        uid?: string | null;
        /** For the moment, key used by the Checkout during the render of the addresses listing */
        id?: number | null;
        /**
         * A flag to know if the address is the default one for the user
         * @default false
         */
        isDefault?: boolean;
        address?: Address;
    }[];
    newsletterSubscriber?: RetailUserNewsletterSubscriber;
    useAppleLogin?: boolean | null;
    useFacebookLogin?: boolean | null;
    useGoogleLogin?: boolean | null;
    idClientBarcodePath?: string | null;
    keycloakUuidQRCodePath?: string | null;
    userSegments?: {
        brandCode?: string;
        segment?: number | null;
    }[];
}

export interface RetailUserWrite {
    firstName: string;
    lastName: string;
    /** @format date-time */
    birthDate: string | null;
    phone: string | null;
    subscribe: boolean | null;
}

export interface RetailUserCreate {
    firstName?: string | null;
    lastName?: string | null;
    /** @format email */
    email: string;
    locale?: string | null;
    sezaneLegacyId?: string | null;
    octobreLegacyId?: string | null;
    /** @format uuid */
    shopUuid?: string | null;
    newsletterSubscriber?: RetailUserNewsletterSubscriber;
}

export interface RetailUserList {
    keycloakId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string | null;
}

export interface RetailOrderList {
    status?: string;
    reference?: number;
    /**
     * Validation date
     * @format date-time
     */
    validatedAt?: string | null;
    /** Total number of products in the order (include LMS) */
    totalProductQuantity?: number;
    /** Total price tax included, with currency */
    totalDisplayPrice?: string;
    displayImageUrl?: string | null;
    /** Source of the order (web, web-retail, retail) */
    source?: string;
    /** Number of pending order returns */
    pendingOrderReturnsCount?: number;
    /** Number of processed order returns */
    processedOrderReturnsCount?: number;
}

export interface AdminAddAttribute {
    /** @example "pin_cashier" */
    attributeName?: string;
    /** @example "3021" */
    attributeValue?: string;
}

export interface Rack {
    id?: number | null;
    name: string;
    ean: string;
    missingQuantity: number;
    modelLabel?: string | null;
    variantLabel?: string | null;
    variantSize?: string | null;
    variantImage?: string | null;
    shopQuantity?: number;
    unitPrice?: number | null;
}

export type RetailUserNewsletterSubscriber = {
    /** @deprecated */
    sezaneSubscriptionStatus?: boolean | null;
    /** @deprecated */
    octobreSubscriptionStatus?: boolean | null;
    /** @deprecated */
    petitSezaneSubscriptionStatus?: boolean | null;
    /** @deprecated */
    lesComposantesSubscriptionStatus?: boolean | null;
};

export interface NotificationRequest {
    recipients: {
        install_ids: any[];
    };
    message: {
        title: string;
        body: string;
    };
}

export interface NotificationResponse {
    /** The batch token */
    token?: string;
}

export interface NotificationResponseError {
    code?: string;
    type?: string;
    message?: string;
}

export type GetStockRequest = {
    /** List of labels (e.g. ["Veste Will", "Robe Margareth"]) */
    labels?: string[];
    /** List of EANs */
    eans?: string[];
    /** List of product codes */
    productCodes?: string[];
};

export interface GetStockResponse {
    stocks: {
        /** EAN13 code */
        ean: Ean;
        productCode: string;
        modelName: string;
        color: string;
        size: string;
        image: string;
        /** Price in the selected currency (default: EUR) */
        unitPrice: number;
        stockWeb?: number;
        stockRetail?: ShopStock[];
    };
}

export interface ShopStock {
    /**
     * @format uuid
     * @example "19b84154-218c-469c-a49a-bc1d2fccc16c"
     */
    shopUuid: string;
    quantity?: number;
}

/**
 * EAN13 code
 * @maxLength 13
 * @pattern ^\d{1,13}$
 */
export type Ean = string;

export interface OrderReadReturnsList {
    returns?: OrderReadReturnDetail[];
}

export interface OrderReadReturnDetail {
    id?: number;
    /** @format uuid */
    uid?: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    transporterHandOverDate?: string | null;
    /**
     * @format date-time
     * @default null
     */
    receptionDate?: string | null;
    /** @default "ADMIN" */
    createdBy?: string;
    returnMode?: ReturnMode;
    status?: OrderReturnStatus;
    refundOption?: 'card' | 'credits' | 'cash' | 'global-e';
    returnedItems?: OrderReturnItemRetail[];
    salesTaxRefunds?: {
        avalaraTransactionId?: string | null;
        amountRefunded?: number;
    }[];
}

export interface OrderReturnItemRetail {
    /** @format uid */
    uid?: string;
    expectedQuantity?: number;
    receivedQuantity?: number;
    refundedQuantity?: number;
    orderedQuantity?: number;
    refundableQuantity?: number;
    returnMotive?: string | null;
    localizedMotive?: string | null;
    orderLineItem?: OrderReturnItemProductRetail;
}

export type OrderReturnItemProductRetail = {
    imageUrl?: string;
    ean?: string;
    label?: string;
    /** Variant code of the product (none for ecard) */
    variantCode?: string | null;
    sizeLabel?: string;
    colorLabel?: string | null;
    unitPrice?: number;
    totalPrice?: number;
    lineItemUuid?: string | null;
    orderReference?: number | null;
    customisation?: Customisation;
};

export interface CashierClose {
    /** @format uuid */
    deviceId?: string;
}

export interface SessionCreate {
    /**
     * Device where the session was created
     * @format uuid
     */
    deviceId?: string;
}

export interface SessionDestroy {
    /**
     * Device where the session was destroyed
     * @format uuid
     */
    deviceId?: string;
}

export interface CartPaymentValidate {
    ticketWillBePrinted?: boolean;
    /** @default [] */
    cardTransactions?: CardTransaction[];
    cardType?: string;
    microDonation?: MicroDonation;
}

export interface CardTransaction {
    amount?: number;
    pspReference?: string;
    currency?: string;
}

export type MicroDonation = {
    beneficiary?: string;
    amount?: number;
} | null;

export interface CommercialOperation {
    id: number | null;
    /** @format uuid */
    uid?: string | null;
    /** Nom de l'opération commerciale */
    name: string;
    /**
     * Statut de l'opération : actif ou inactif. Par défaut inactive
     * @default false
     */
    enabled?: boolean;
    /**
     * Date de début de l'opération (facultatif)
     * @format date-time
     * @default null
     * @example 1500658348
     */
    startAt?: string | null;
    /**
     * Date de fin de l'opération (facultatif)
     * @format date-time
     * @default null
     * @example 1500658348
     */
    endAt?: string | null;
    /**
     * Date de suppression de l'opération (soft delete)
     * @format date-time
     * @default null
     */
    deletedAt?: string | null;
    /**
     * Liste des marques éligibles à l'opération
     * @default []
     */
    brands: string[];
    /** Liste des sites, pays et montants éligiles à l'opération */
    sites: Site[];
    currencies: Currency[];
    /** Liste des codes ISO-2 des pays pour lesquels l'opération est éligible */
    countries?: Country[] | null;
    /**
     * sources where voucher campaign is available
     * @default ["ios","android","retail","retail-web","web"]
     */
    sources?: Status[];
    /**
     * Code pour bénéficier de l'opération commerciale
     * @default null
     */
    code?: string | null;
    /**
     * Type d'avantage de l'opération commercial : produit offert et/ou frais de port
     * @default ["product"]
     */
    types?: CommercialOperationType[];
    /** Option de distribution pour l'opération si elle offre des produits */
    distributionMode?: CommercialDistributionMode;
    /** @default [] */
    offeredProducts?: OfferedProducts[];
    /** Type de descente des produits offerts */
    offeredProductsType?: OfferedProductsType;
    /**
     * Liste des ids des shippings modes éligibles pour l'opération commerciale, si option "Frais de port offerts" est sélectionné
     * @default null
     */
    shippingModes?: number[] | null;
    /**
     * Liste des variant codes éligibles à l'opération
     * @default null
     */
    allowedVariantCodes?: string[] | null;
    /**
     * Nombre d’unités minimum permettant l'éligibilité à l'opération commerciale
     * @default 1
     */
    minimumAllowedVariantUnit?: number;
    /**
     * Liste des segments clients éligibles à l'opération
     * @default null
     */
    userSegments?: UserSegmentRead[] | null;
    /**
     * Ids des tags client éligibles à l'opération commerciale
     * @default null
     */
    userTagIds?: number[] | null;
    /**
     * Nombre de commandes à comptabiliser
     * @default null
     */
    nthOrder?: number | null;
    /**
     * Data à partir de laquelle on doit comptabiliser le nombre de commandes
     * @format date
     * @default null
     * @example 1500595200
     */
    nthOrderCountDate?: string | null;
    /**
     * Sources des commandes à comptabiliser
     * @default ["ios","android","retail","retail-web","web"]
     */
    nthOrderSources?: Status[];
    /**
     * Nombre maximum  de fois où l’opération peut être appliquée à un client
     * @default 1
     */
    limitPerUser?: number;
    /**
     * Nombre maximum  de fois où l’opération peut être appliquée à une commande
     * @default 1
     */
    limitPerOrder?: number;
    /** @default 0 */
    usageTotalCount?: number;
    /** @default null */
    translations?: Record<string, CommercialOperationTranslation>;
    /**
     * ids des opérations à exclure
     * @default null
     */
    excludedCommercialOperations?: string[] | null;
    /**
     * Si vrai, l'opération sera éligible pour les clients dont l'anniversaire est dans le mois en cours
     * @default false
     */
    eligibleForCustomerBirthdayMonth?: boolean;
    /**
     * Nombre de jours avant l'anniversaire, pour lesquels l'opération sera éligible
     * @default 0
     */
    daysBeforeBirthDate?: number;
    /**
     * Nombre de jours après l'anniversaire, pour lesquels l'opération sera éligible
     * @default 0
     */
    daysAfterBirthDate?: number;
    /**
     * Restreindre une opération commerciale à des paniers possédant au moins 1 produit d’une liste de marques
     * @default null
     */
    requiredProductBrands?: string[] | null;
    /**
     * Détermine si une opération doit être affiché et si elle peut être ajoutée avant ou après le paiement
     * @default "display_and_applicable_on_cart"
     */
    appliance?: 'display_and_applicable_on_cart' | 'display_and_applicable_on_order' | 'hide_and_applicable_on_order';
    /**
     * Affichage du produit dans le mail de confirmation de commande. OBLIGATOIREMENT 'true' si l'ajout du cadeau est manuel.
     * @default true
     */
    giftVisibleInOrderConfirmation?: boolean;
    /**
     * Stratégie d'application de l'opération commerciale
     * @default null
     */
    applianceStrategy?: 'sequential' | 'random' | null;
    /**
     * Nombre d'éligibilité par jour lorsque la stratégie est 'random'
     * @min 1
     * @default null
     */
    appliancePerDay?: number | null;
    /**
     * Liste des code postaux
     * @default null
     */
    zipCodes?: string[] | null;
    /**
     * Méthode d'ajout du produit cadeau
     * @default null
     */
    additionMethod?: 'manual' | 'automatic' | null;
}

export interface CommercialOperationTranslation {
    locale?: string;
    /**
     * Titre traduit de l'opération commercial
     * @default null
     */
    title?: string | null;
    /**
     * Description traduite de l'opération commercial
     * @default null
     */
    description?: string | null;
}

/**
 * Liste des segments éligibles à l’opération
 * @min -2
 * @max 10
 */
export type UserSegmentRead = number;

/** Type de descente des produits offerts */
export enum OfferedProductsType {
    Asylum = 'asylum',
    AdditionalProduct = 'additional-product',
}

/**
 * Liste des EANS regroupé par CP, offert par l'opération commercial. Obligatoire si elle offre des produits
 * @example "[ { "productCode": "S202UND003G", "eans": [ "3664841518840", "3664841518857", "3664841518864" ] } ]"
 */
export type OfferedProducts = {
    productCode?: string;
    /** @default [] */
    eans?: string[];
} | null;

/**
 * Option de distribution pour l'opération si elle offre des produits
 * @default null
 */
export enum CommercialDistributionMode {
    PrioritizedSequential = 'prioritized_sequential',
    RandomSequential = 'random_sequential',
    Simultaneous = 'simultaneous',
    CustomerChoice = 'customer_choice',
}

/** type d'avantage d'une opération commerciale */
export enum CommercialOperationType {
    Product = 'product',
    ShippingFees = 'shipping_fees',
}

/** Source */
export enum Status {
    Ios = 'ios',
    Android = 'android',
    Retail = 'retail',
    RetailWeb = 'retail-web',
    Web = 'web',
}

export interface Country {
    /** Country code */
    countryCode?: string;
    /**
     * Liste des états par pays
     * @default null
     */
    states?: string[] | null;
}

export interface References {
    credit_creation_reasons?: (
        | 'influence'
        | 'gift_credit'
        | 'employees'
        | 'competition_game'
        | 'replacement_expired_credit'
        | 'conversion_gift_card_currency'
        | 'gift_card_invalid'
    )[];
    order_payment_status?: string[];
    order_status?: string[];
    order_sources?: string[];
    order_types?: string[];
    order_return_status?: string[];
    order_return_types?: string[];
    shipping_types?: string[];
    voucher_campaign_discount_types?: string[];
    voucher_campaign_format_type?: string[];
    order_return_motives?: {
        name?: string;
        translation?: string;
    }[];
    warehouses?: {
        name?: string;
        uid?: string;
    }[];
    /** List of globalE countryCode */
    globale_countries?: string[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key]);
        return keys
            .map(key =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async response => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then(data => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch(e => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Sezane OMS private API
 * @version 0.0.1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    omsPrivate = {
        /**
         * No description
         *
         * @tags oms, parameter
         * @name GetParameter
         * @summary Get parameter
         * @request GET:/oms-private/v1/parameters/{id}
         */
        getParameter: (id: string, params: RequestParams = {}) =>
            this.request<
                Parameter,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/parameters/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, parameter
         * @name UpdateParameter
         * @summary Update a parameter
         * @request PUT:/oms-private/v1/parameters/{id}
         */
        updateParameter: (id: string, data: Parameter, params: RequestParams = {}) =>
            this.request<
                Parameter,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/parameters/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, export
         * @name ExportOrderListing
         * @request POST:/oms-private/v1/export/order
         */
        exportOrderListing: (data: OrderExportBody, params: RequestParams = {}) =>
            this.request<
                {
                    result?: string;
                },
                void
            >({
                path: `/oms-private/v1/export/order`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, export
         * @name ExportCustomerListing
         * @request POST:/oms-private/v1/export/customer
         */
        exportCustomerListing: (data: CustomerExportBody, params: RequestParams = {}) =>
            this.request<
                {
                    result?: string;
                },
                void
            >({
                path: `/oms-private/v1/export/customer`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, export
         * @name ExportEcardListing
         * @request POST:/oms-private/v1/export/ecard
         */
        exportEcardListing: (data: EcardExportBody, params: RequestParams = {}) =>
            this.request<
                {
                    result?: string;
                },
                void
            >({
                path: `/oms-private/v1/export/ecard`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, tag
         * @name CreateTag
         * @summary Create a new customer Tag
         * @request POST:/oms-private/v1/user-tags
         */
        createTag: (data: Tag, params: RequestParams = {}) =>
            this.request<
                TagList,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user-tags`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, tag
         * @name ListTag
         * @summary Get tags list
         * @request GET:/oms-private/v1/user-tags
         */
        listTag: (
            query?: {
                /** search on label field */
                search?: string;
                /** Order by createdAt, label or userCount */
                order?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                TagList[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user-tags`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, tag
         * @name UpdateTag
         * @summary Edit a Tag
         * @request PUT:/oms-private/v1/user-tags/{id}
         */
        updateTag: (id: string, data: Tag, params: RequestParams = {}) =>
            this.request<
                TagList,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user-tags/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, tag
         * @name DeleteTag
         * @summary Delete a customer Tag
         * @request DELETE:/oms-private/v1/user-tags/{id}
         */
        deleteTag: (id: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user-tags/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, tag
         * @name ImportTag
         * @summary Add a tag for users based on a CSV of emails
         * @request POST:/oms-private/v1/user-tags/import/{id}
         */
        importTag: (id: string, params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user-tags/import/${id}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShippingModes
         * @summary Get all shipping modes
         * @request GET:/oms-private/v1/shipping-mode
         */
        getShippingModes: (
            query?: {
                /** Filter by status. Ex : 'enabled' => 'true' */
                enabled?: boolean;
                /** Search by name. Ex : 'search' => 'Colissimo' */
                search?: string;
                /** Search exactly by fields. Ex : 'search_exact_match' => 'shippingModeAttributesGroups.logisticCode', 'search' => 'LOGICSTIC_CODE' */
                search_exact_match?: string;
                /** Order by, for example 'order[usageTotalCount]' => 'ASC', 'order[name.raw]' => 'DESC', 'order[id]' => 'ASC', 'order[startAt]' => 'DESC' */
                order?: string[];
                /** Filter by countries. Ex : 'countries' => 'FR,ES' */
                countries?: string[];
                brands?: string[];
                /** Filter by shipping types. Ex : 'shippingTypes[]' => 'store_delivery' */
                shippingTypes?: string[];
                /**
                 * Show deleted shipping modes. Ex : 'showDeleted' => 'true'
                 * @default false
                 */
                showDeleted?: boolean;
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                ShippingMode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name CreateShippingMode
         * @summary Create a new shipping mode
         * @request POST:/oms-private/v1/shipping-mode
         */
        createShippingMode: (data: ShippingMode, params: RequestParams = {}) =>
            this.request<
                ShippingMode,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShippingMode
         * @summary Get a shipping mode
         * @request GET:/oms-private/v1/shipping-mode/{id}
         */
        getShippingMode: (id: string, params: RequestParams = {}) =>
            this.request<
                ShippingMode,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name UpdateShippingMode
         * @summary Update a shippingMode
         * @request PUT:/oms-private/v1/shipping-mode/{id}
         */
        updateShippingMode: (id: string, data: ShippingMode, params: RequestParams = {}) =>
            this.request<
                ShippingMode,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name DeleteShippingMode
         * @summary Delete a shipping mode
         * @request DELETE:/oms-private/v1/shipping-mode/{id}
         */
        deleteShippingMode: (id: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * @description Returns shipping modes id for which a currency is missing for a specified country and brand
         *
         * @tags oms, shipping
         * @name GetShippingModesMissingCurrency
         * @summary Get shipping modes with missing currency
         * @request GET:/oms-private/v1/shipping-mode/check-currency
         */
        getShippingModesMissingCurrency: (data: CountryCurrencyMatch, params: RequestParams = {}) =>
            this.request<ShippingModeMissingCurrency, any>({
                path: `/oms-private/v1/shipping-mode/check-currency`,
                method: 'GET',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping, order-return
         * @name GetShippingOrderReturnModes
         * @summary Returns all order return modes associated to the given shipping mode
         * @request GET:/oms-private/v1/shipping-mode/{id}/order-return-modes
         */
        getShippingOrderReturnModes: (
            id: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                ShippingOrderReturnMode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode/${id}/order-return-modes`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping, order-return
         * @name UpdateShippingOrderReturnModes
         * @summary Edit associated order return modes to the given shipping mode
         * @request PUT:/oms-private/v1/shipping-mode/{id}/order-return-modes
         */
        updateShippingOrderReturnModes: (
            id: string,
            query: {
                locale: string;
            },
            data: ShippingOrderReturnMode[],
            params: RequestParams = {}
        ) =>
            this.request<
                ShippingOrderReturnMode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode/${id}/order-return-modes`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShops
         * @summary Get all shops
         * @request GET:/oms-private/v1/shop
         */
        getShops: (
            query?: {
                /** Get only enabled shops (value should be equal to "1") */
                enabled?: boolean;
                /**
                 * Search by description
                 * @default ""
                 */
                description?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                Shop[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shop`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name CreateShop
         * @summary Create a new shop
         * @request POST:/oms-private/v1/shop
         */
        createShop: (data: Shop, params: RequestParams = {}) =>
            this.request<
                Shop,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shop`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShop
         * @summary Get a shop
         * @request GET:/oms-private/v1/shop/{id}
         */
        getShop: (id: string, params: RequestParams = {}) =>
            this.request<
                Shop,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shop/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name UpdateShop
         * @summary Update a shop
         * @request PUT:/oms-private/v1/shop/{id}
         */
        updateShop: (id: string, data: Shop, params: RequestParams = {}) =>
            this.request<
                Shop,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shop/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name DeleteShop
         * @summary Delete a shop
         * @request DELETE:/oms-private/v1/shop/{id}
         */
        deleteShop: (id: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shop/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShippingModePouchParams
         * @summary Get a shipping mode pouch params
         * @request GET:/oms-private/v1/shipping-mode/pouch/params
         */
        getShippingModePouchParams: (params: RequestParams = {}) =>
            this.request<
                ShippingModePouchParams,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode/pouch/params`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name UpdateShippingModePouchParams
         * @summary Update a shippingMode pouch params
         * @request PUT:/oms-private/v1/shipping-mode/pouch/params
         */
        updateShippingModePouchParams: (data: ShippingModePouchParams, params: RequestParams = {}) =>
            this.request<
                ShippingModePouchParams,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/shipping-mode/pouch/params`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShippingModesForWarehouse
         * @summary List of delivery methods available for warehouse overload
         * @request GET:/oms-private/v1/shipping-mode-warehouse
         */
        getShippingModesForWarehouse: (params: RequestParams = {}) =>
            this.request<ShippingModeLight[], any>({
                path: `/oms-private/v1/shipping-mode-warehouse`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name GetCrons
         * @summary Get oms scheduled tasks.
         * @request GET:/oms-private/v1/crons
         */
        getCrons: (params: RequestParams = {}) =>
            this.request<
                ScheduledTask[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/crons`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name UpdateCrons
         * @summary Update oms scheduled tasks.
         * @request PUT:/oms-private/v1/crons
         */
        updateCrons: (data: ScheduledTask[], params: RequestParams = {}) =>
            this.request<
                ScheduledTask[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/crons`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name DeleteCron
         * @summary Delete oms scheduled task.
         * @request DELETE:/oms-private/v1/crons/{name}
         */
        deleteCron: (name: string, params: RequestParams = {}) =>
            this.request<
                ScheduledTask[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/crons/${name}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, email-template
         * @name ListEmailTemplates
         * @summary Get email templates
         * @request GET:/oms-private/v1/email-templates
         */
        listEmailTemplates: (params: RequestParams = {}) =>
            this.request<
                EmailTemplate[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/email-templates`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, email-template
         * @name ListEmailTemplateVersions
         * @summary Get email template versions by template
         * @request GET:/oms-private/v1/email-template-versions/{emailTemplateId}
         */
        listEmailTemplateVersions: (emailTemplateId: number, params: RequestParams = {}) =>
            this.request<
                EmailTemplateVersionList,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/email-template-versions/${emailTemplateId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, email-template
         * @name CreateEmailTemplateVersion
         * @summary Create a new email template version
         * @request POST:/oms-private/v1/email-template-versions/{emailTemplateId}
         */
        createEmailTemplateVersion: (
            emailTemplateId: number,
            data: CreateEmailTemplateVersion,
            params: RequestParams = {}
        ) =>
            this.request<
                EmailTemplateVersionList,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/email-template-versions/${emailTemplateId}`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, email-template
         * @name UpdateEmailTemplateVersion
         * @summary Update an email template version
         * @request PUT:/oms-private/v1/email-template-versions/{emailTemplateId}/{emailTemplateVersionId}
         */
        updateEmailTemplateVersion: (
            emailTemplateId: number,
            emailTemplateVersionId: number,
            data: UpdateEmailTemplateVersion,
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/email-template-versions/${emailTemplateId}/${emailTemplateVersionId}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, email-template
         * @name DeleteEmailTemplateVersion
         * @summary Delete an email template version
         * @request DELETE:/oms-private/v1/email-template-versions/{emailTemplateId}/{emailTemplateVersionId}
         */
        deleteEmailTemplateVersion: (
            emailTemplateId: number,
            emailTemplateVersionId: number,
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/email-template-versions/${emailTemplateId}/${emailTemplateVersionId}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name GetOrderReturnModes
         * @summary Get all order return modes
         * @request GET:/oms-private/v1/order-return-modes
         */
        getOrderReturnModes: (
            query?: {
                /** If true, return only enabled return modes */
                enabled?: boolean;
                brands?: string[];
                currencyCode?: string[];
                /** Filter on return types. The elements must be set separately in query, e.g. returnTypes[0]=...&returnTypes[1]=... */
                returnTypes?: 'store_return' | 'return_label' | 'special';
                /** search on name */
                search?: string;
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnMode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-return-modes`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name CreateOrderReturnMode
         * @summary Create a new return mode
         * @request POST:/oms-private/v1/order-return-modes
         */
        createOrderReturnMode: (data: OrderReturnMode, params: RequestParams = {}) =>
            this.request<
                OrderReturnMode,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-return-modes`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name GetOrderReturnMode
         * @summary Get a return mode
         * @request GET:/oms-private/v1/order-return-modes/{uid}
         */
        getOrderReturnMode: (uid: string, params: RequestParams = {}) =>
            this.request<
                OrderReturnMode,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-return-modes/${uid}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name UpdateOrderReturnMode
         * @summary Update a return mode
         * @request PUT:/oms-private/v1/order-return-modes/{uid}
         */
        updateOrderReturnMode: (uid: string, data: OrderReturnMode, params: RequestParams = {}) =>
            this.request<
                OrderReturnMode,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-return-modes/${uid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name DeleteOrderReturnMode
         * @summary Delete a return mode
         * @request DELETE:/oms-private/v1/order-return-modes/{uid}
         */
        deleteOrderReturnMode: (uid: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-return-modes/${uid}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name CreateAdminOrderReturn
         * @summary Create a new order return
         * @request POST:/oms-private/v1/order/{orderReference}/return
         */
        createAdminOrderReturn: (
            orderReference: string,
            query: {
                locale: string;
            },
            data: OrderReturnCreatePayload,
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order/${orderReference}/return`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name GetOrderReturns
         * @summary Get paginated order returns
         * @request GET:/oms-private/v1/order-returns
         */
        getOrderReturns: (
            query: {
                locale: string;
                /** Filter on return status. The elements must be set separately in query, e.g. status[0]=...&status[1]=... */
                status?: OrderReturnStatus[];
                /** search on order return (id, uid), user (email, first name, last name, keycloak id), order (reference) */
                search?: string;
                /** Filter on brand code (sezane, octobre) */
                brandCodes?: string[];
                /** Filter on return mode uid. */
                returnModes?: string[];
                /** Filter on creation date */
                createdAt?: string[];
                /** Filter on simple/complex returns (a complex return has at least one item not linked to a line item) */
                type?: ('simple' | 'complex')[];
                /** @default 1 */
                page?: number;
                /** @default 10 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnListElement[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-returns`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name GetOrderReturnDetail
         * @summary Get order return
         * @request GET:/oms-private/v1/order-returns/{uid}
         */
        getOrderReturnDetail: (
            uid: string,
            query?: {
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-returns/${uid}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name AdminDeleteOrderReturn
         * @summary Delete order return
         * @request DELETE:/oms-private/v1/order-returns/{uid}
         */
        adminDeleteOrderReturn: (
            uid: string,
            query?: {
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-returns/${uid}`,
                method: 'DELETE',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name UpdateOrderReturn
         * @summary Update order return (only works when status = 'pending' and transporter hand-over date is null)
         * @request PUT:/oms-private/v1/order-returns/{uid}
         */
        updateOrderReturn: (
            uid: string,
            data: OrderReturnUpdatePayload,
            query?: {
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-returns/${uid}`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, return, oms
         * @name AdminCancelOrderReturn
         * @summary Cancel order return
         * @request PUT:/oms-private/v1/order-returns/{uid}/cancel
         */
        adminCancelOrderReturn: (
            uid: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order-returns/${uid}/cancel`,
                method: 'PUT',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, return, oms
         * @name AdminProcessOrderReturn
         * @summary Mark order return as processed
         * @request PUT:/oms-private/v1/order-returns/{uid}/mark-as-processed
         */
        adminProcessOrderReturn: (
            uid: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order-returns/${uid}/mark-as-processed`,
                method: 'PUT',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name ProcessOrderReturn
         * @summary Manually process order return and refund the items using the provided quantities
         * @request PUT:/oms-private/v1/order-returns/{uid}/process
         */
        processOrderReturn: (
            uid: string,
            data: ProcessOrderReturnInput,
            query?: {
                /** Locale used to return the order return info. 'FR' by default */
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-returns/${uid}/process`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, order-return
         * @name UpdateOrderReturnItemRefundedQuantity
         * @summary Manually update refunded quantity from order return line item
         * @request PUT:/oms-private/v1/order-returns/{uid}/items/refunded-quantity
         */
        updateOrderReturnItemRefundedQuantity: (
            uid: string,
            data: ReturnItemsRefundedQuantity,
            query?: {
                /** Locale used to return the order return info. 'FR' by default */
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order-returns/${uid}/items/refunded-quantity`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-return, oms, dhl
         * @name GetPrivateOrderReturnLabel
         * @summary Get order return label
         * @request GET:/oms-private/v1/order-return/{uid}/label
         */
        getPrivateOrderReturnLabel: (uid: string, params: RequestParams = {}) =>
            this.request<
                File,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order-return/${uid}/label`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return, order-refund
         * @name ImportOrderReturns
         * @summary Create batch of order returns, and refund them, using csv
         * @request POST:/oms-private/v1/order-returns/import
         */
        importOrderReturns: (
            data: {
                /** @format binary */
                returns_import?: File;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                | {
                      line?: number;
                      message?: string;
                  }[]
                | void
                | {
                      type: string;
                      title: string;
                      status: number;
                      detail: string;
                      'invalid-params': InvalidParams;
                  }
            >({
                path: `/oms-private/v1/order-returns/import`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order-refund
         * @name CancelRefund
         * @summary Cancel order refund : only works with credits refund, if the credit is still active and hasn't been spent
         * @request PUT:/oms-private/v1/order-refunds/{uid}/cancel
         */
        cancelRefund: (uid: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order-refunds/${uid}/cancel`,
                method: 'PUT',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, widget, statistics
         * @name GetTurnoverWidget
         * @summary Get Turnover widget data
         * @request GET:/oms-private/v1/widget/turnover
         */
        getTurnoverWidget: (
            query?: {
                /**
                 * Any date-time format is accepted there
                 * @default "now"
                 */
                date?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<TurnoverWidget, any>({
                path: `/oms-private/v1/widget/turnover`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, widget, statistics
         * @name GetBestProductsWidget
         * @summary Get best products widget data
         * @request GET:/oms-private/v1/widget/best-products
         */
        getBestProductsWidget: (
            query?: {
                /**
                 * The collection page number
                 * @default 1
                 */
                page?: number;
                /**
                 * The number of items per page
                 * @min 1
                 * @default 20
                 */
                itemsPerPage?: number;
                /** @default "sezane" */
                brandCode?: 'sezane' | 'octobre';
                /**
                 * Any date-time format is accepted there
                 * @default "now"
                 */
                date?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<BestProductsWidget[], any>({
                path: `/oms-private/v1/widget/best-products`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description Add rights to a role
         *
         * @tags oms-private, admin, role, rights
         * @name AddRightsToRole
         * @summary Add rights to a role
         * @request POST:/oms-private/v1/admin/roles/{role_uuid}/rights
         */
        addRightsToRole: (roleUuid: string, data: RightsBody, params: RequestParams = {}) =>
            this.request<Role, void>({
                path: `/oms-private/v1/admin/roles/${roleUuid}/rights`,
                method: 'POST',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * @description Remove rights from a role
         *
         * @tags oms-private, admin, role, rights
         * @name RemoveRightsFromRole
         * @summary Remove rights from a role
         * @request DELETE:/oms-private/v1/admin/roles/{role_uuid}/rights
         */
        removeRightsFromRole: (roleUuid: string, data: RightsBody, params: RequestParams = {}) =>
            this.request<Role, void>({
                path: `/oms-private/v1/admin/roles/${roleUuid}/rights`,
                method: 'DELETE',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get rights of a role
         *
         * @tags oms-private, admin, role, rights
         * @name GetRightsOfRole
         * @summary Get rights of a role
         * @request GET:/oms-private/v1/admin/roles/{role_uuid}/rights
         */
        getRightsOfRole: (roleUuid: string, params: RequestParams = {}) =>
            this.request<
                {
                    rights?: ListOfRights;
                },
                void
            >({
                path: `/oms-private/v1/admin/roles/${roleUuid}/rights`,
                method: 'GET',
                ...params,
            }),

        /**
         * @description Update rights of a role
         *
         * @tags oms-private, admin, role, rights
         * @name UpdateRightsOfRole
         * @summary Update rights of a role
         * @request PUT:/oms-private/v1/admin/roles/{role_uuid}/rights
         */
        updateRightsOfRole: (roleUuid: string, data: RightsBody, params: RequestParams = {}) =>
            this.request<Role, void>({
                path: `/oms-private/v1/admin/roles/${roleUuid}/rights`,
                method: 'PUT',
                body: data,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get all roles
         *
         * @tags oms-private, admin, role
         * @name GetAllRoles
         * @summary Get all roles
         * @request GET:/oms-private/v1/admin/roles
         */
        getAllRoles: (params: RequestParams = {}) =>
            this.request<
                {
                    roles?: ListOfRoles;
                },
                any
            >({
                path: `/oms-private/v1/admin/roles`,
                method: 'GET',
                ...params,
            }),

        /**
         * @description Create a role
         *
         * @tags oms-private, admin, role
         * @name CreateRole
         * @summary Create a role
         * @request POST:/oms-private/v1/admin/roles
         */
        createRole: (data: RoleRequestBody, params: RequestParams = {}) =>
            this.request<Role, any>({
                path: `/oms-private/v1/admin/roles`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Delete a role
         *
         * @tags oms-private, admin, role
         * @name DeleteRole
         * @summary Delete a role
         * @request DELETE:/oms-private/v1/admin/roles/{role_uuid}
         */
        deleteRole: (roleUuid: string, params: RequestParams = {}) =>
            this.request<Role, void>({
                path: `/oms-private/v1/admin/roles/${roleUuid}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * @description Get a role
         *
         * @tags oms-private, admin, role
         * @name GetRole
         * @summary Get a role
         * @request GET:/oms-private/v1/admin/roles/{role_uuid}
         */
        getRole: (roleUuid: string, params: RequestParams = {}) =>
            this.request<Role, void>({
                path: `/oms-private/v1/admin/roles/${roleUuid}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * @description Update a role
         *
         * @tags oms-private, admin, role
         * @name UpdateRole
         * @summary Update a role
         * @request PUT:/oms-private/v1/admin/roles/{role_uuid}
         */
        updateRole: (roleUuid: string, data: RoleRequestBody, params: RequestParams = {}) =>
            this.request<Role, void>({
                path: `/oms-private/v1/admin/roles/${roleUuid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Get all rights
         *
         * @tags oms-private, admin, right
         * @name GetAllRights
         * @summary Get all rights
         * @request GET:/oms-private/v1/admin/rights
         */
        getAllRights: (params: RequestParams = {}) =>
            this.request<
                {
                    rights?: ListOfRights;
                },
                any
            >({
                path: `/oms-private/v1/admin/rights`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, admin, rights
         * @name GetUserRights
         * @summary Get the rights of the current user
         * @request GET:/oms-private/v1/admin/user/rights
         */
        getUserRights: (params: RequestParams = {}) =>
            this.request<
                {
                    rights?: ListOfRights;
                },
                any
            >({
                path: `/oms-private/v1/admin/user/rights`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name PaymentMethodList
         * @summary Get payment method list
         * @request GET:/oms-private/v1/payment-transaction/payment-method
         */
        paymentMethodList: (params: RequestParams = {}) =>
            this.request<
                UuidArray,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/payment-transaction/payment-method`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags warhouse, logistic
         * @name GetWarehouse
         * @summary Get warehouse details by uid
         * @request GET:/oms-private/v1/warehouse/{uid}
         */
        getWarehouse: (uid: string, params: RequestParams = {}) =>
            this.request<
                Warehouse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/warehouse/${uid}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags warhouse, logistic
         * @name UpdateWarehouse
         * @summary Update warehouse
         * @request PUT:/oms-private/v1/warehouse/{uid}
         */
        updateWarehouse: (uid: string, data: Warehouse, params: RequestParams = {}) =>
            this.request<
                Warehouse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/warehouse/${uid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags warhouse, logistic
         * @name DeleteWarehouse
         * @summary Delete warehouse
         * @request DELETE:/oms-private/v1/warehouse/{uid}
         */
        deleteWarehouse: (uid: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/warehouse/${uid}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags warhouse, logistic
         * @name ListWarehouse
         * @summary Get warehouse list
         * @request GET:/oms-private/v1/warehouse
         */
        listWarehouse: (params: RequestParams = {}) =>
            this.request<
                Warehouse[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/warehouse`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags warhouse, logistic
         * @name CreateWarehouse
         * @summary Create warehouse
         * @request POST:/oms-private/v1/warehouse
         */
        createWarehouse: (data: Warehouse, params: RequestParams = {}) =>
            this.request<
                Warehouse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/warehouse`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags countrySetting, logistic
         * @name UpdateCountryLogisticSetting
         * @summary Update country logistic setting
         * @request PUT:/oms-private/v1/logistic/country/setting/{uid}
         */
        updateCountryLogisticSetting: (uid: string, data: CountrySetting, params: RequestParams = {}) =>
            this.request<
                CountrySetting,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/logistic/country/setting/${uid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags countrySetting, logistic
         * @name ListCountryLogisticSetting
         * @summary Get conutry setting list
         * @request GET:/oms-private/v1/logistic/country/setting
         */
        listCountryLogisticSetting: (
            query?: {
                /** Filter by enabled */
                enabled?: boolean;
                /** Filter by grouping */
                grouping?: boolean;
                /** Filter by optimization */
                optimization?: boolean;
                /** User locale */
                locale?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CountrySetting[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/logistic/country/setting`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, nf525
         * @name Nf525JournalCodes
         * @summary Get NF525 list of available codes
         * @request POST:/oms-private/v1/nf525/journal/codes
         */
        nf525JournalCodes: (params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/nf525/journal/codes`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, nf525
         * @name Nf525JournalCreate
         * @summary Create a new NF525 Journal entry
         * @request POST:/oms-private/v1/nf525/journal
         */
        nf525JournalCreate: (data: JournalCreate, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/nf525/journal`,
                method: 'POST',
                body: data,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name CartsList
         * @summary Get carts list
         * @request GET:/oms-private/v1/carts
         */
        cartsList: (
            query: {
                /** brandCode */
                brand: string;
                /** siteId */
                site: number;
                /** search on email or ean */
                search?: string;
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartList[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/carts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name CartRead
         * @summary Get a cart
         * @request GET:/oms-private/v1/carts/{id}
         */
        cartRead: (id: string, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/carts/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * @description List of shipping mode attributes available for mass import, depending on the context passed as parameters
         *
         * @tags oms-private, cart
         * @name MassImportShippingModeList
         * @summary Get list of available shipping modes (attributes)
         * @request GET:/oms-private/v1/mass-import/shipping-modes
         */
        massImportShippingModeList: (
            query: {
                brandCode: string;
                siteCode: string;
                currencyCode: string;
                localeCode: string;
                countryCode: string;
                /** @default null */
                stateCode?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                ShippingMode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/shipping-modes`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * @description List of commercial operations associated with a cart.
         *
         * @tags oms-private, cart
         * @name CartsCommercialOperationsList
         * @summary Get list of commercial operations
         * @request GET:/oms-private/v1/carts/{id}/commercial-operations
         */
        cartsCommercialOperationsList: (id: string, params: RequestParams = {}) =>
            this.request<
                CartCommercialOperation[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/carts/${id}/commercial-operations`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name MassImportCreateCarts
         * @summary Generate carts from an csv file
         * @request POST:/oms-private/v1/cart/mass-import
         */
        massImportCreateCarts: (data: MassImportCreateCartsBody, params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/mass-import`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name MassImportCartList
         * @summary Get mass import cart list
         * @request GET:/oms-private/v1/mass-import/{id}/cart
         */
        massImportCartList: (
            id: number,
            query?: {
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartMassImportRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/cart`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name ExpireAllCarts
         * @summary Expires all import carts
         * @request DELETE:/oms-private/v1/mass-import/{id}/cart
         */
        expireAllCarts: (id: number, params: RequestParams = {}) =>
            this.request<
                void,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/mass-import/${id}/cart`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name MassImportCartUpdateShippingAddress
         * @summary Updating shipping address
         * @request PUT:/oms-private/v1/mass-import/{id}/cart/{uid}/shipping-address
         */
        massImportCartUpdateShippingAddress: (
            id: number,
            uid: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/cart/${uid}/shipping-address`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name MassImportCartUpdateBillingAddress
         * @summary Updating shipping address
         * @request PUT:/oms-private/v1/mass-import/{id}/cart/{uid}/billing-address
         */
        massImportCartUpdateBillingAddress: (
            id: number,
            uid: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/cart/${uid}/billing-address`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name MassImportUpdateGiftMessage
         * @summary Updating a gift message to the cart
         * @request PUT:/oms-private/v1/mass-import/{id}/cart/{uid}/gift-message
         */
        massImportUpdateGiftMessage: (id: number, uid: string, data: GiftMessage, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/cart/${uid}/gift-message`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name MassImportDeleteGiftMessage
         * @summary Delete a gift message from cart
         * @request DELETE:/oms-private/v1/mass-import/{id}/cart/{uid}/gift-message
         */
        massImportDeleteGiftMessage: (id: number, uid: string, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/cart/${uid}/gift-message`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name UpdateCustomisation
         * @summary Update customisation of a product
         * @request PUT:/oms-private/v1/mass-import/{id}/cart/{uid}/product/{lineItemUid}/customisation
         */
        updateCustomisation: (
            id: number,
            uid: string,
            lineItemUid: string,
            data: {
                rows?: string[] | null;
                color?: string | null;
                position?: string | null;
                type?: string | null;
                format?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/cart/${uid}/product/${lineItemUid}/customisation`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name DeleteCustomisation
         * @summary Delete the customisation of a product in the cart
         * @request DELETE:/oms-private/v1/mass-import/{id}/cart/{uid}/product/{lineItemUid}/customisation
         */
        deleteCustomisation: (id: number, uid: string, lineItemUid: string, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/cart/${uid}/product/${lineItemUid}/customisation`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart
         * @name GetCustomisation
         * @summary Get customisation of a product and the customisation properties associated
         * @request GET:/oms-private/v1/mass-import/{id}/cart/{uid}/product/{lineItemUid}/customisation
         */
        getCustomisation: (id: number, uid: string, lineItemUid: string, params: RequestParams = {}) =>
            this.request<
                CustomisationDetails,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/mass-import/${id}/cart/${uid}/product/${lineItemUid}/customisation`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, oms-private
         * @name UserList
         * @summary Get users list
         * @request GET:/oms-private/v1/users
         */
        userList: (
            query?: {
                /** search on email or uuid */
                search?: string;
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
                order?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                UserList[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/users`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, oms-private
         * @name UserRead
         * @summary Get user
         * @request GET:/oms-private/v1/users/{keycloakId}
         */
        userRead: (
            keycloakId: string,
            query: {
                keycloakId: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                UserRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/users/${keycloakId}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, oms-private
         * @name UserUpdate
         * @summary Update user
         * @request PUT:/oms-private/v1/users/{keycloakId}
         */
        userUpdate: (
            keycloakId: string,
            query: {
                keycloakId: string;
            },
            data: UserWrite,
            params: RequestParams = {}
        ) =>
            this.request<
                UserRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/users/${keycloakId}`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, oms-private
         * @name UserDelete
         * @summary Soft delete user
         * @request DELETE:/oms-private/v1/users/{keycloakId}
         */
        userDelete: (
            keycloakId: string,
            query: {
                keycloakId: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/users/${keycloakId}`,
                method: 'DELETE',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, oms-private
         * @name UserReactivate
         * @summary Reactivate a user
         * @request PUT:/oms-private/v1/users/{keycloakId}/reactivate
         */
        userReactivate: (
            keycloakId: string,
            query: {
                keycloakId: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                UserRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/users/${keycloakId}/reactivate`,
                method: 'PUT',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, oms-private
         * @name UserResetPassword
         * @summary Reset user password
         * @request PUT:/oms-private/v1/users/{keycloakId}/reset-password
         */
        userResetPassword: (
            keycloakId: string,
            query: {
                keycloakId: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                UserCredentials,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/users/${keycloakId}/reset-password`,
                method: 'PUT',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, oms-private, wallet
         * @name WalletApplePush
         * @summary Send push notification for given user and/or brand
         * @request POST:/oms-private/v1/wallet/apple/pass/push
         */
        walletApplePush: (data: WalletApplePushRequest, params: RequestParams = {}) =>
            this.request<
                UserCredentials,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/wallet/apple/pass/push`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name OrderList
         * @summary Get orders list
         * @request GET:/oms-private/v1/orders
         */
        orderList: (
            query?: {
                /** search */
                search?: string;
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
                order?: string[];
                brands?: string[];
                sites?: string[];
                createdAt?: string[];
                shopUid?: string[];
                paymentMethods?: string[];
                hasReturn?: boolean;
                isOmsOrder?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderList[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name OrderRead
         * @summary Get order
         * @request GET:/oms-private/v1/orders/{orderReference}
         */
        orderRead: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name OrderUpdate
         * @summary Update order
         * @request PUT:/oms-private/v1/orders/{orderReference}
         */
        orderUpdate: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}`,
                method: 'PUT',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name CancelOrder
         * @summary cancel order (with no siblings)
         * @request DELETE:/oms-private/v1/orders/{orderReference}
         */
        cancelOrder: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name OrderTransitionsHistoryList
         * @summary list order transitions history
         * @request GET:/oms-private/v1/orders/{orderReference}/transitions
         */
        orderTransitionsHistoryList: (orderReference: string, params: RequestParams = {}) =>
            this.request<
                OrderTransitions,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/transitions`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name ExportOrderCustomisations
         * @summary Export customisations
         * @request GET:/oms-private/v1/orders/export-customisation
         */
        exportOrderCustomisations: (
            query: {
                /** @format date */
                dateFrom: string;
                /** @format date */
                dateTo?: string;
                /** Is "all" by default and could be a warehouse UUID */
                warehouse?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                Ean,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/orders/export-customisation`,
                method: 'GET',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name ImportShopSpots
         * @summary Import shop spots
         * @request POST:/oms-private/v1/shop-spots/import
         */
        importShopSpots: (params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/shop-spots/import`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name UpdateOrderStatus
         * @summary Update order status
         * @request PUT:/oms-private/v1/orders/{orderReference}/status
         */
        updateOrderStatus: (
            orderReference: number,
            data: {
                transition?: 'cancel' | 'validated';
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/status`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name ApplyOrderTransition
         * @summary Apply transition on an order
         * @request GET:/oms-private/v1/orders/{orderReference}/transition/{transition}
         */
        applyOrderTransition: (orderReference: number, transition: string, params: RequestParams = {}) =>
            this.request<
                object,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/transition/${transition}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name ManuallyMergeOrders
         * @summary Manually merge orders together
         * @request PUT:/oms-private/v1/orders/{orderReference}/manual-merge
         */
        manuallyMergeOrders: (
            orderReference: number,
            data: {
                references?: number[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/manual-merge`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return
         * @name AdminGetReturnLabel
         * @summary Get the pdf file of the order return label
         * @request GET:/oms-private/v1/orders/{orderReference}/return-label
         */
        adminGetReturnLabel: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                File,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/orders/${orderReference}/return-label`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return
         * @name AdminOrderHasReturnLabel
         * @summary Check if an order has an order return label
         * @request GET:/oms-private/v1/order/{orderReference}/has-return-label
         */
        adminOrderHasReturnLabel: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order/${orderReference}/has-return-label`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, order
         * @name AdminCreateOrderRefund
         * @summary refund an order
         * @request POST:/oms-private/v1/orders/{orderReference}/refund
         */
        adminCreateOrderRefund: (orderReference: string, data: OrderRefundBody, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/refund`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, order, refund
         * @name V1OrdersRefundFreezeCreate
         * @summary add a freeze for refunds on the order, preventing any refunds from being made
         * @request POST:/oms-private/v1/orders/{orderReference}/refund-freeze
         */
        v1OrdersRefundFreezeCreate: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/refund-freeze`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, order, refund
         * @name V1OrdersRefundFreezeDelete
         * @summary Deletes the freeze for refunds on the order
         * @request DELETE:/oms-private/v1/orders/{orderReference}/refund-freeze
         */
        v1OrdersRefundFreezeDelete: (reference: number, orderReference: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/orders/${orderReference}/refund-freeze`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name OrderCommercialRefundForm
         * @summary Returns specified order and some data to do a commercial refund for it
         * @request GET:/oms-private/v1/orders/{orderReference}/commercial-refund/application
         */
        orderCommercialRefundForm: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                {
                    order?: OrderRead;
                    reasonsByCategory: {
                        category?: 'transport' | 'logistic' | 'faulty' | 'other_category';
                        reasons?: CommercialRefundReason[];
                    }[];
                    /** the default beneficiary (i.e. the client) */
                    beneficiary?: string;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/commercial-refund/application`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name CreateOrderCommercialRefund
         * @summary Create a commercial refund on an order
         * @request POST:/oms-private/v1/orders/{orderReference}/commercial-refund/application
         */
        createOrderCommercialRefund: (
            orderReference: number,
            data: OrderCommercialRefundRequest,
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/commercial-refund/application`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name ImportTrackingCode
         * @summary Import orders tracking codes
         * @request POST:/oms-private/v1/order/import/tracking
         */
        importTrackingCode: (
            data: {
                /** @format binary */
                orders_tracking?: File;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order/import/tracking`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name GetGiftMessage
         * @summary Get gift message PDF for a given order
         * @request GET:/oms-private/v1/order/{orderReference}/gift-message
         */
        getGiftMessage: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                File,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order/${orderReference}/gift-message`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, refund, oms
         * @name ComputeOrderRefundAmount
         * @summary Compute order refund amount
         * @request POST:/oms-private/v1/order/{reference}/refund/amount
         */
        computeOrderRefundAmount: (reference: string, data: OrderRefundBody, params: RequestParams = {}) =>
            this.request<
                OrderRefundAmount,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/order/${reference}/refund/amount`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name UpdateTrackingCode
         * @summary Update tracking codes
         * @request POST:/oms-private/v1/orders/{orderReference}/tracking
         */
        updateTrackingCode: (orderReference: number, data: OrderUpdateTrackingRequest, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/tracking`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-complaint, order-refund
         * @name ImportOrderComplaints
         * @summary Create batch of order complaints using csv
         * @request POST:/oms-private/v1/orders/complaint/import
         */
        importOrderComplaints: (
            data: {
                /** @format binary */
                complaints_import?: File;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                | {
                      line?: number;
                      message?: string;
                  }[]
                | void
                | {
                      type: string;
                      title: string;
                      status: number;
                      detail: string;
                      'invalid-params': InvalidParams;
                  }
            >({
                path: `/oms-private/v1/orders/complaint/import`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name MassTransitionToDelivered
         * @summary Transition the orders corresponding to the given references to "delivered"
         * @request POST:/oms-private/v1/orders/mass-transition-to-delivered
         */
        massTransitionToDelivered: (
            data: {
                /** @format binary */
                mass_transition_to_delivered?: File;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /** references of the orders who could not be transitioned because they either don't exist or the transition is not possible */
                    errors?: number[];
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/mass-transition-to-delivered`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order
         * @name MassImportConfirmOrders
         * @summary Create orders from mass import carts
         * @request POST:/oms-private/v1/mass-import/{id}/orders
         */
        massImportConfirmOrders: (id: number, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/mass-import/${id}/orders`,
                method: 'POST',
                ...params,
            }),

        /**
         * @description List of commercial operations associated with order.
         *
         * @tags oms-private, order
         * @name OrdersCommercialOperationsList
         * @summary Get list of commercial operations
         * @request GET:/oms-private/v1/orders/{id}/commercial-operations
         */
        ordersCommercialOperationsList: (id: string, params: RequestParams = {}) =>
            this.request<
                OrderCommercialOperation[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${id}/commercial-operations`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, oms-private
         * @name OrderForceValidation
         * @summary Force order validation
         * @request PUT:/oms-private/v1/orders/{orderReference}/force-validation
         */
        orderForceValidation: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/orders/${orderReference}/force-validation`,
                method: 'PUT',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags invoice, order
         * @name GetInvoice
         * @summary Get invoice PDF for a given order
         * @request GET:/oms-private/v1/invoice/{orderReference}
         */
        getInvoice: (
            orderReference: number,
            query?: {
                /** Invoice type: client (default) or log */
                type?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                File,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/invoice/${orderReference}`,
                method: 'GET',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags invoice, order
         * @name GenerateInvoice
         * @summary Generate invoice PDF for a given order
         * @request GET:/oms-private/v1/invoice/{orderReference}/generate
         */
        generateInvoice: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/invoice/${orderReference}/generate`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags invoice, order
         * @name GetOrderReturnInvoice
         * @summary Generate invoice PDF for a given order return
         * @request GET:/oms-private/v1/invoice/order-return/{uid}
         */
        getOrderReturnInvoice: (uid: number, params: RequestParams = {}) =>
            this.request<
                File,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/invoice/order-return/${uid}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, user-address
         * @name ListAddresses
         * @summary List all addresses for a user
         * @request GET:/oms-private/v1/user/{keycloakId}/address
         */
        listAddresses: (keycloakId: string, params: RequestParams = {}) =>
            this.request<
                {
                    /** @format uuid */
                    uid?: string | null;
                    /** For the moment, key used by the Checkout during the render of the addresses listing */
                    id?: number | null;
                    /**
                     * A flag to know if the address is the default one for the user
                     * @default false
                     */
                    isDefault?: boolean;
                    address?: Address;
                }[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/${keycloakId}/address`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, user-address
         * @name UpdateAddress
         * @summary Update a user address
         * @request PUT:/oms-private/v1/user/{keycloakId}/address/{uid}
         */
        updateAddress: (
            keycloakId: string,
            uid: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                Address,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/${keycloakId}/address/${uid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-comments, oms-private
         * @name UserCommentsList
         * @summary Get users comments list
         * @request GET:/oms-private/v1/user/{keycloakId}/comment
         */
        userCommentsList: (keycloakId: string, params: RequestParams = {}) =>
            this.request<
                UserComment[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/${keycloakId}/comment`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-comments, oms-private
         * @name UserCommentCreate
         * @summary Create a new user comment
         * @request POST:/oms-private/v1/user/{keycloakId}/comment
         */
        userCommentCreate: (keycloakId: string, data: UserComment, params: RequestParams = {}) =>
            this.request<
                UserComment[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/${keycloakId}/comment`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-voucher, oms-private
         * @name VoucherCodeUserCreate
         * @summary Create a new assosiation user voucher code
         * @request POST:/oms-private/v1/user/{keycloakId}/voucher
         */
        voucherCodeUserCreate: (keycloakId: string, data: VoucherCodeBody, params: RequestParams = {}) =>
            this.request<
                VoucherCodeUser[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/${keycloakId}/voucher`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-credits, oms-private
         * @name UserCreditsList
         * @summary Get paginated users credits list
         * @request GET:/oms-private/v1/user/credit
         */
        userCreditsList: (
            query?: {
                /** search on firstname, name, email, code or order reference */
                search?: string;
                /** search all credits by keycloakId, bypass all other filters */
                search_by_keycloakId?: string;
                /** @default 1 */
                page?: number;
                /** @default 30 */
                itemsPerPage?: number;
                order?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                UserCredit[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/credit`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-credits, oms-private
         * @name UserCreditsCreate
         * @summary Create a new user credit
         * @request POST:/oms-private/v1/user/credit
         */
        userCreditsCreate: (data: UserCredit, params: RequestParams = {}) =>
            this.request<
                UserCredit[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/credit`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-credits, oms-private
         * @name UserCreditsGet
         * @summary Get one user credits
         * @request GET:/oms-private/v1/user/credit/{id}
         */
        userCreditsGet: (id: number, params: RequestParams = {}) =>
            this.request<
                UserCredit,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/credit/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-credits, oms-private
         * @name UserCreditsPut
         * @summary Update user credits
         * @request PUT:/oms-private/v1/user/credit/{id}
         */
        userCreditsPut: (id: number, data: UserCredit, params: RequestParams = {}) =>
            this.request<
                UserCredit,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/credit/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-credits, oms-private
         * @name UserCreditsPutStatus
         * @summary Disabled one user credits
         * @request PUT:/oms-private/v1/user/credit/{id}/status
         */
        userCreditsPutStatus: (id: number, data: UserCredit, params: RequestParams = {}) =>
            this.request<
                UserCredit,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/credit/${id}/status`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user-credits, oms-private
         * @name UserCreditsImport
         * @summary Import credits
         * @request POST:/oms-private/v1/user/credit/import
         */
        userCreditsImport: (params: RequestParams = {}) =>
            this.request<
                ImportCreditResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/user/credit/import`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name GetPackagings
         * @summary Get all shipping modes
         * @request GET:/oms-private/v1/packaging
         */
        getPackagings: (params: RequestParams = {}) =>
            this.request<
                PackagingList[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name CreatePackaging
         * @summary Create a new packaging
         * @request POST:/oms-private/v1/packaging
         */
        createPackaging: (data: PackagingWrite, params: RequestParams = {}) =>
            this.request<
                PackagingWrite,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name GetPackaging
         * @summary Get a packaging
         * @request GET:/oms-private/v1/packaging/{id}
         */
        getPackaging: (id: string, params: RequestParams = {}) =>
            this.request<
                Packaging,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name UpdatePackaging
         * @summary Update a packaging
         * @request PUT:/oms-private/v1/packaging/{id}
         */
        updatePackaging: (id: string, data: PackagingWrite, params: RequestParams = {}) =>
            this.request<
                PackagingWrite,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name DeletePackaging
         * @summary Delete a packaging
         * @request DELETE:/oms-private/v1/packaging/{id}
         */
        deletePackaging: (id: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name ExportPackaging
         * @summary Export linked eans of a packaging
         * @request GET:/oms-private/v1/packaging/export/{id}
         */
        exportPackaging: (id: string, params: RequestParams = {}) =>
            this.request<
                Ean,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging/export/${id}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name ImportPackaging
         * @summary Import eans for a packaging
         * @request POST:/oms-private/v1/packaging/import/{id}
         */
        importPackaging: (id: string, params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging/import/${id}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name GetPackagingProduct
         * @summary Get all the sizes of a product and knowing if they are linked to the packaging
         * @request GET:/oms-private/v1/packaging/{id}/product/{uuid}
         */
        getPackagingProduct: (id: string, uuid: string, params: RequestParams = {}) =>
            this.request<
                PackagingProduct,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging/${id}/product/${uuid}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, packaging
         * @name UpdatePackagingProduct
         * @summary Add or remove sizes of one product linked to a packaging
         * @request PUT:/oms-private/v1/packaging/{id}/product/{uuid}
         */
        updatePackagingProduct: (
            id: string,
            uuid: string,
            data: {
                /** Eans of size from product to link to a packaging */
                eans?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                PackagingProduct,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/packaging/${id}/product/${uuid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, salestax
         * @name SalesTaxList
         * @summary Get sales tax list
         * @request GET:/oms-private/v1/salestax
         */
        salesTaxList: (
            query?: {
                /** Search by State code */
                state?: string;
                /** Search by Alpha-2 country code */
                country?: string;
                /** Filter by sales tax Sezane true/false */
                salesTaxSezane?: boolean;
                /** Filter by sales tax Octobre true/false */
                salesTaxOctobre?: boolean;
                /** Order by 'country' value ASC/DESC */
                order?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                SalesTax[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/salestax`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, salestax
         * @name SalesTaxUpdate
         * @summary Update a sales tax
         * @request PUT:/oms-private/v1/salestax/{id}
         */
        salesTaxUpdate: (id: string, data: SalesTax, params: RequestParams = {}) =>
            this.request<
                SalesTax,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/salestax/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stock-alerts, oms-private
         * @name StockAlertList
         * @summary Get stock alerts list
         * @request GET:/oms-private/v1/stock-alerts
         */
        stockAlertList: (
            query?: {
                /** search on product or ean */
                search?: string;
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
                order?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                StockAlertList[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/stock-alerts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags stock-alerts, oms-private
         * @name StockAlertMultiDelete
         * @summary Multi delete alert stock
         * @request DELETE:/oms-private/v1/stock-alerts
         */
        stockAlertMultiDelete: (
            query: {
                ids: number[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/stock-alerts`,
                method: 'DELETE',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags stock-alerts, oms-private
         * @name StockAlertUpdate
         * @summary Update stock alert
         * @request PUT:/oms-private/v1/stock-alerts/{id}
         */
        stockAlertUpdate: (
            id: string,
            query: {
                id: number;
            },
            data: StockAlertWrite,
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/stock-alerts/${id}`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags stock-alerts, oms-private
         * @name StockAlertDelete
         * @summary Delete alert stock
         * @request DELETE:/oms-private/v1/stock-alerts/{id}
         */
        stockAlertDelete: (
            id: string,
            query: {
                id: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/stock-alerts/${id}`,
                method: 'DELETE',
                query: query,
                ...params,
            }),

        /**
         * No description
         *
         * @tags stock-alerts, oms-private
         * @name StockAlertImport
         * @summary Import alerts from csv
         * @request POST:/oms-private/v1/stock-alerts/import
         */
        stockAlertImport: (
            query: {
                sendAt: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                UuidArray,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/stock-alerts/import`,
                method: 'POST',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, vat
         * @name GetVatSettings
         * @summary Get all VAT settings
         * @request GET:/oms-private/v1/vat
         */
        getVatSettings: (params: RequestParams = {}) =>
            this.request<
                Vat[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/vat`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, vat
         * @name UpdateVatSettings
         * @summary Update VAT settings
         * @request PUT:/oms-private/v1/vat/{id}
         */
        updateVatSettings: (id: string, data: Vat, params: RequestParams = {}) =>
            this.request<
                Vat,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/vat/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, vat
         * @name BatchUpdateVatSettings
         * @summary Batch update VAT settings
         * @request PUT:/oms-private/v1/vat/batch
         */
        batchUpdateVatSettings: (data: Vat[], params: RequestParams = {}) =>
            this.request<
                Vat,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/vat/batch`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ecards, oms-private
         * @name EcardList
         * @summary Get paginated e-cards list
         * @request GET:/oms-private/v1/ecard
         */
        ecardList: (
            query?: {
                /** search on buyer or receiver firstname, name, email, code, order reference of purchase or usage */
                search?: string;
                /** @default 1 */
                page?: number;
                /** @default 30 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                EcardRead[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/ecard`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ecards, oms-private
         * @name EcardUpdate
         * @summary Update one ecard
         * @request PUT:/oms-private/v1/ecard/{id}
         */
        ecardUpdate: (id: any, data: EcardWrite, params: RequestParams = {}) =>
            this.request<
                EcardRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/ecard/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ecards, oms-private
         * @name ResendEcard
         * @summary Resend Ecard email
         * @request POST:/oms-private/v1/ecard/{id}/resend
         */
        resendEcard: (id: any, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/ecard/${id}/resend`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, newsletter
         * @name ListNewsletterSubscribers
         * @summary Get newsletter subscribers list
         * @request GET:/oms-private/v1/newsletter/subscribers
         */
        listNewsletterSubscribers: (
            query?: {
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
                /** Search on email, Salesforce ID, Keycloak ID, Phone Number */
                search?: string;
                /** @default ["createdAt"] */
                order?: (
                    | 'salesforceId'
                    | 'userEmail.lowercase'
                    | 'sezaneSubscriptionStatus'
                    | 'octobreSubscriptionStatus'
                    | 'sezaneSubscriptionDate'
                    | 'octobreSubscriptionDate'
                    | 'subscriptionLocale'
                    | 'user.keycloakId'
                    | 'createdAt'
                )[];
                /** Filter on subscription status */
                status?: boolean | null;
                /**
                 * Filter on subscription locale
                 * @example "fr"
                 */
                subscriptionLocale?: string | null;
                /**
                 * Filter on subscription medium
                 * @example "sms"
                 */
                subscriptionMedium?: string | null;
                /**
                 * Filter on marketing brand code
                 * @example "sezane"
                 */
                marketingBrandCode?: string | null;
                /**
                 * Filter on subscription date after
                 * @format date
                 */
                'subscriptionDate[after]'?: string | null;
                /**
                 * Filter on subscription date before
                 * @format date
                 */
                'subscriptionDate[before]'?: string | null;
                /**
                 * Filter on unsubscription date after
                 * @format date
                 */
                'unsubscriptionDate[after]'?: string | null;
                /**
                 * Filter on unsubscription date before
                 * @format date
                 */
                'unsubscriptionDate[before]'?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    id?: number | null;
                    email: string;
                    phoneNumber?: string | null;
                    firstName?: string | null;
                    lastName?: string | null;
                    salesforceId?: string | null;
                    keycloakId?: string | null;
                    /** @deprecated */
                    sezaneLegacyId?: string | null;
                    /** @deprecated */
                    octobreLegacyId?: string | null;
                    /** @deprecated */
                    sezaneSubscriptionStatus: boolean | null;
                    /** @deprecated */
                    octobreSubscriptionStatus: boolean | null;
                    /**
                     * @deprecated
                     * @format date-time
                     */
                    sezaneSubscriptionDate?: string | null;
                    /**
                     * @deprecated
                     * @format date-time
                     */
                    octobreSubscriptionDate?: string | null;
                    subscriptionLocale: string;
                    subscriptions?: Notifications;
                }[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/newsletter/subscribers`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, newsletter
         * @name NewsletterUpdate
         * @summary Update newsletter subscriber
         * @request PUT:/oms-private/v1/newsletter/subscribers/{id}
         */
        newsletterUpdate: (
            id: string,
            data: {
                id?: number | null;
                email: string;
                phoneNumber?: string | null;
                firstName?: string | null;
                lastName?: string | null;
                salesforceId?: string | null;
                keycloakId?: string | null;
                /** @deprecated */
                sezaneLegacyId?: string | null;
                /** @deprecated */
                octobreLegacyId?: string | null;
                /** @deprecated */
                sezaneSubscriptionStatus: boolean | null;
                /** @deprecated */
                octobreSubscriptionStatus: boolean | null;
                /**
                 * @deprecated
                 * @format date-time
                 */
                sezaneSubscriptionDate?: string | null;
                /**
                 * @deprecated
                 * @format date-time
                 */
                octobreSubscriptionDate?: string | null;
                subscriptionLocale: string;
                subscriptions?: Notifications;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    id?: number | null;
                    email: string;
                    phoneNumber?: string | null;
                    firstName?: string | null;
                    lastName?: string | null;
                    salesforceId?: string | null;
                    keycloakId?: string | null;
                    /** @deprecated */
                    sezaneLegacyId?: string | null;
                    /** @deprecated */
                    octobreLegacyId?: string | null;
                    /** @deprecated */
                    sezaneSubscriptionStatus: boolean | null;
                    /** @deprecated */
                    octobreSubscriptionStatus: boolean | null;
                    /**
                     * @deprecated
                     * @format date-time
                     */
                    sezaneSubscriptionDate?: string | null;
                    /**
                     * @deprecated
                     * @format date-time
                     */
                    octobreSubscriptionDate?: string | null;
                    subscriptionLocale: string;
                    subscriptions?: Notifications;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/newsletter/subscribers/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, newsletter
         * @name NewsletterImport
         * @summary Import a list of subscribers based on a CSV file
         * @request POST:/oms-private/v1/newsletter/subscribers/import
         */
        newsletterImport: (params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/newsletter/subscribers/import`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags newsletter, oms-private
         * @name UserNewsletterGet
         * @summary Get a user's subscriptions
         * @request GET:/oms-private/v1/users/{keycloakId}/subscriptions
         */
        userNewsletterGet: (keycloakId: string, params: RequestParams = {}) =>
            this.request<
                {
                    newsletter?: NotificationSubscription[] | null;
                    sms?: ({
                        /** @format date-time */
                        subscriptionConfirmationDate?: string | null;
                    } & NotificationSubscription)[];
                    postal_mail?: NotificationSubscription[] | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/users/${keycloakId}/subscriptions`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags newsletter, oms-private
         * @name SubscriberSubscriptionsUpdate
         * @summary Update a subscriber's subscriptions
         * @request PUT:/oms-private/v1/subscribers/{id}/subscriptions
         */
        subscriberSubscriptionsUpdate: (
            id: number,
            data: {
                newsletter?: NotificationSubscription[] | null;
                sms?: ({
                    /** @format date-time */
                    subscriptionConfirmationDate?: string | null;
                } & NotificationSubscription)[];
                postal_mail?: NotificationSubscription[] | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    newsletter?: NotificationSubscription[] | null;
                    sms?: ({
                        /** @format date-time */
                        subscriptionConfirmationDate?: string | null;
                    } & NotificationSubscription)[];
                    postal_mail?: NotificationSubscription[] | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/subscribers/${id}/subscriptions`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsCreateClients
         * @summary Create a new voucher campaign for customers
         * @request POST:/oms-private/v1/voucher-campaign/clients
         */
        voucherCampaignsCreateClients: (data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/clients`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsCreateFriendsFamily
         * @summary Create a new voucher campaign for friends and family
         * @request POST:/oms-private/v1/voucher-campaign/friends_family
         */
        voucherCampaignsCreateFriendsFamily: (data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/friends_family`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsCreateInfluence
         * @summary Create a new voucher campaign for influence
         * @request POST:/oms-private/v1/voucher-campaign/influence
         */
        voucherCampaignsCreateInfluence: (data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/influence`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsCreateEmployees
         * @summary Create a new voucher campaign for staff
         * @request POST:/oms-private/v1/voucher-campaign/employees
         */
        voucherCampaignsCreateEmployees: (data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/employees`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsCreateRetail
         * @summary Create a new voucher campaign for retail
         * @request POST:/oms-private/v1/voucher-campaign/retail
         */
        voucherCampaignsCreateRetail: (data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/retail`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsList
         * @summary list voucher campaign
         * @request GET:/oms-private/v1/voucher-campaign/retail
         */
        voucherCampaignsList: (params: RequestParams = {}) =>
            this.request<
                VoucherCampaign[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/retail`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsDetail
         * @summary get voucher campaign
         * @request GET:/oms-private/v1/voucher-campaign/{id}
         */
        voucherCampaignsDetail: (id: string, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsDelete
         * @summary Delete a voucher campaign
         * @request DELETE:/oms-private/v1/voucher-campaign/{id}
         */
        voucherCampaignsDelete: (id: any, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsUpdateClients
         * @summary Update a customer voucher campaign
         * @request PUT:/oms-private/v1/voucher-campaign/{id}/clients
         */
        voucherCampaignsUpdateClients: (id: any, data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/clients`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsUpdateFriendsFamily
         * @summary Update a friends and family voucher campaign
         * @request PUT:/oms-private/v1/voucher-campaign/{id}/friends_family
         */
        voucherCampaignsUpdateFriendsFamily: (id: any, data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/friends_family`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsUpdateInfluence
         * @summary Update an influence voucher campaign
         * @request PUT:/oms-private/v1/voucher-campaign/{id}/influence
         */
        voucherCampaignsUpdateInfluence: (id: any, data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/influence`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsUpdateEmployees
         * @summary Update a staff campaign
         * @request PUT:/oms-private/v1/voucher-campaign/{id}/employees
         */
        voucherCampaignsUpdateEmployees: (id: any, data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/employees`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsUpdateRetail
         * @summary Update a retail campaign
         * @request PUT:/oms-private/v1/voucher-campaign/{id}/retail
         */
        voucherCampaignsUpdateRetail: (id: any, data: VoucherCampaign, params: RequestParams = {}) =>
            this.request<
                VoucherCampaign,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/retail`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsExportExludedProduct
         * @summary Export excluded products of a voucher campaign
         * @request GET:/oms-private/v1/voucher-campaign/{id}/excluded-product/export
         */
        voucherCampaignsExportExludedProduct: (id: any, params: RequestParams = {}) =>
            this.request<
                Ean,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/excluded-product/export`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsImportExludedProduct
         * @summary Import excluded products of a voucher campaign
         * @request POST:/oms-private/v1/voucher-campaign/{id}/excluded-product/import
         */
        voucherCampaignsImportExludedProduct: (id: any, params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/excluded-product/import`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsExportAllowedProduct
         * @summary Export allowed products of a voucher campaign
         * @request GET:/oms-private/v1/voucher-campaign/{id}/allowed-product/export
         */
        voucherCampaignsExportAllowedProduct: (id: any, params: RequestParams = {}) =>
            this.request<
                Ean,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/allowed-product/export`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsImportAllowedProduct
         * @summary Import allowed products of a voucher campaign
         * @request POST:/oms-private/v1/voucher-campaign/{id}/allowed-product/import
         */
        voucherCampaignsImportAllowedProduct: (id: any, params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/allowed-product/import`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsImportUsers
         * @summary Import users on a voucher campaign
         * @request POST:/oms-private/v1/voucher-campaign/{id}/users/import
         */
        voucherCampaignsImportUsers: (id: any, params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/users/import`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsImportUsers2
         * @summary Import  admin users on a voucher campaign
         * @request POST:/oms-private/v1/voucher-campaign/{id}/user-admins/import
         * @originalName voucherCampaignsImportUsers
         * @duplicate
         */
        voucherCampaignsImportUsers2: (id: any, params: RequestParams = {}) =>
            this.request<
                ImportResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/user-admins/import`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsListCodes
         * @summary List voucher campaign codes
         * @request GET:/oms-private/v1/voucher-campaign/{id}/code
         */
        voucherCampaignsListCodes: (id: any, params: RequestParams = {}) =>
            this.request<
                VoucherCode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/code`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignsExportCode
         * @summary Export voucher codes of a voucher campaign
         * @request GET:/oms-private/v1/voucher-campaign/{id}/code/export
         */
        voucherCampaignsExportCode: (id: any, params: RequestParams = {}) =>
            this.request<
                Ean,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-campaign/${id}/code/export`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags voucher-campaigns, oms-private
         * @name VoucherCampaignCodeUpdate
         * @summary Update voucher campaign code
         * @request PUT:/oms-private/v1/voucher-code/{id}
         */
        voucherCampaignCodeUpdate: (id: any, data: VoucherCode, params: RequestParams = {}) =>
            this.request<
                VoucherCode,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/voucher-code/${id}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, stats
         * @name GetShopStats
         * @summary Generate and get the shop stats
         * @request GET:/oms-private/v1/retail/stats
         */
        getShopStats: (
            query: {
                /**
                 * Uid of the shop where the order is placed. Prevails over the shopId when given.
                 * @format uuid
                 * @example "19b84154-218c-469c-a49a-bc1d2fccc16c"
                 */
                shopUid: string;
                /**
                 * Relative date.
                 * @default "now"
                 */
                date?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<ShopDailyStatistics, any>({
                path: `/oms-private/v1/retail/stats`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, stats
         * @name SendShopEmailStats
         * @summary Generate and send the shop stats via email
         * @request POST:/oms-private/v1/retail/email-stats
         */
        sendShopEmailStats: (
            data: {
                /**
                 * @format uuid
                 * @minLength 36
                 * @maxLength 36
                 */
                shopUuid: string;
                fromName: string;
                /** @format email */
                fromEmail: string;
                /** @default "now" */
                date?: string;
                notes?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/oms-private/v1/retail/email-stats`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name CreateRetailCart
         * @summary Generate a new cart with retail information
         * @request POST:/oms-private/v1/retail/cart
         * @secure
         */
        createRetailCart: (
            query: {
                /** Id of the shop where the order is placed. Required if a shop uid is not provided. */
                shopId?: number;
                /**
                 * Uid of the shop where the order is placed. Prevails over the shopId when given.
                 * @format uuid
                 * @example "19b84154-218c-469c-a49a-bc1d2fccc16c"
                 */
                shopUid?: string;
                brandCode: string;
            },
            data?: {
                /** User keycloak identifier */
                keycloakId?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name GetRetailCart
         * @summary Get a retail cart
         * @request GET:/oms-private/v1/retail/cart/{uid}
         * @secure
         */
        getRetailCart: (uid: string, params: RequestParams = {}) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name DeleteRetailCart
         * @summary Delete a retail cart
         * @request DELETE:/oms-private/v1/retail/cart/{uid}
         * @secure
         */
        deleteRetailCart: (uid: string, params: RequestParams = {}) =>
            this.request<
                void,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/cart/${uid}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description A a product web or shop to a retail cart. If the same product is already in the cart the quantity will be updated but in some cases, as customisable product, a new distinct item will be added
         *
         * @tags oms-private, cart, retail
         * @name AddProductToRetailCart
         * @summary Add a product in a retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/add-product/{ean}
         * @secure
         */
        addProductToRetailCart: (
            uid: string,
            ean: string,
            query?: {
                /**
                 * Prevent the addition of a shop product to the retail cart if there is no available stock in the shop
                 * @default false
                 */
                failIfOutOfStock?: boolean;
            },
            data?: AddProductRequestBody,
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/add-product/${ean}`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name DeleteProductInRetailCart
         * @summary Delete an entry in a retail cart
         * @request DELETE:/oms-private/v1/retail/cart/{uid}/product/{lineItemUid}
         * @secure
         */
        deleteProductInRetailCart: (uid: string, lineItemUid: string, params: RequestParams = {}) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/product/${lineItemUid}`,
                method: 'DELETE',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name UpdateProductInRetailCart
         * @summary Update an entry in a retail cart
         * @request PUT:/oms-private/v1/retail/cart/{uid}/product/{lineItemUid}
         * @secure
         */
        updateProductInRetailCart: (
            uid: string,
            lineItemUid: string,
            data: {
                quantity?: number | null;
            },
            query?: {
                /**
                 * Prevent the addition of a shop product to the retail cart if there is no available stock in the shop
                 * @default false
                 */
                failIfOutOfStock?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/product/${lineItemUid}`,
                method: 'PUT',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Customisation only on customizable web product
         *
         * @tags oms-private, cart, retail
         * @name UpdateCustomisationInRetailCart
         * @summary Update the customisation of a product in a retail cart
         * @request PUT:/oms-private/v1/retail/cart/{uid}/product/{lineItemUid}/customisation
         * @secure
         */
        updateCustomisationInRetailCart: (
            uid: string,
            lineItemUid: string,
            data: {
                rows?: string[] | null;
                color?: string | null;
                position?: string | null;
                type?: string | null;
                format?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/product/${lineItemUid}/customisation`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name DeleteCustomisationInRetailCart
         * @summary Delete the customisation of a product in a retail cart
         * @request DELETE:/oms-private/v1/retail/cart/{uid}/product/{lineItemUid}/customisation
         * @secure
         */
        deleteCustomisationInRetailCart: (uid: string, lineItemUid: string, params: RequestParams = {}) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/product/${lineItemUid}/customisation`,
                method: 'DELETE',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name ConsumeEcardOnRetailCart
         * @summary Apply ecard on retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/consume-ecard
         * @secure
         */
        consumeEcardOnRetailCart: (
            uid: string,
            data: {
                /** Ecard Code */
                code?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/consume-ecard`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name AddUserCreditOnRetailCart
         * @summary Add a user credit on retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/user-credit
         * @secure
         */
        addUserCreditOnRetailCart: (
            uid: string,
            data: {
                /** User credit code */
                userCreditCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/user-credit`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name AddVoucherOnRetailCart
         * @summary Add a voucher on retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/voucher
         * @secure
         */
        addVoucherOnRetailCart: (
            uid: string,
            data: {
                /** Voucher code */
                voucherCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/voucher`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Used to delete a voucher by its code
         *
         * @tags oms-private, cart, retail
         * @name DeleteVoucherCodeOnRetailCart
         * @summary Delete a voucher on retail cart
         * @request DELETE:/oms-private/v1/retail/cart/{uid}/voucher
         * @deprecated
         */
        deleteVoucherCodeOnRetailCart: (
            uid: string,
            data: {
                /** Voucher code */
                voucherCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/voucher`,
                method: 'DELETE',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description When buying an ecard
         *
         * @tags oms-private, cart, retail
         * @name AddEcardOnRetailCart
         * @summary Add ecard on a retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/add-ecard
         * @secure
         */
        addEcardOnRetailCart: (
            uid: string,
            data: {
                /**
                 * empty string
                 * @default ""
                 */
                ean?: string;
                category?: string | null;
                productType?: string | null;
                name?: string | null;
                slug?: string | null;
                color?: string | null;
                image?: string | null;
                priceTaxIncluded?: number;
                priceTaxExcluded?: number | null;
                message?: string | null;
                senderLastname?: string;
                senderFirstname?: string;
                receiverFirstname?: string;
                receiverLastname?: string;
                receiverEmail?: string;
                /** @format date-time */
                sendAt?: string;
                remainingAmount?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/add-ecard`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Returns the eligible delivery methods for the cart. There may be restrictions depending on product, location, etc.
         *
         * @tags oms-private, cart, retail
         * @name GetCartShippingModes
         * @summary Returns the eligible delivery methods
         * @request GET:/oms-private/v1/retail/cart/{uid}/shipping-modes
         * @secure
         */
        getCartShippingModes: (
            uid: string,
            query?: {
                countryCode?: string;
                stateCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                ShippingMode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/shipping-modes`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name AddShippingOnRetailCart
         * @summary Set the shipping mode
         * @request POST:/oms-private/v1/retail/cart/{uid}/shipping/{shippingModeUid}
         * @secure
         */
        addShippingOnRetailCart: (uid: string, shippingModeUid: string, params: RequestParams = {}) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/shipping/${shippingModeUid}`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description Set the shipping mode and matching address when selecting pickUp or home delivery. None to specify when only shop or e-card)
         *
         * @tags oms-private, cart, retail
         * @name AddShippingOnRetailCartAndAddress
         * @summary Save the shipping mode and related address
         * @request POST:/oms-private/v1/retail/cart/{uid}/shipping/{shippingModeUid}/address
         * @secure
         */
        addShippingOnRetailCartAndAddress: (
            uid: string,
            shippingModeUid: string,
            data?: AddShippingRequestBody,
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/shipping/${shippingModeUid}/address`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description A billing address is set when the retail cart contains web products
         *
         * @tags oms-private, cart, retail
         * @name AddBillingAddressOnRetailCart
         * @summary Add the billing address on a retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/billing-address
         * @secure
         */
        addBillingAddressOnRetailCart: (
            uid: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/billing-address`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description We need to assign a retail cart to a user if it was created with a Guest account but then web products were added
         *
         * @tags oms-private, cart, retail
         * @name AssignRetailCartToUser
         * @summary Associate a user to a retail cart
         * @request PUT:/oms-private/v1/retail/cart/{uid}/user/{keycloakId}
         * @secure
         */
        assignRetailCartToUser: (uid: string, keycloakId: string, params: RequestParams = {}) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/user/${keycloakId}`,
                method: 'PUT',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name ExonerateVatCart
         * @summary Indicate whether vat is exonerated for cart or not
         * @request PUT:/oms-private/v1/retail/cart/{uid}/exonerated-vat
         * @secure
         */
        exonerateVatCart: (
            uid: string,
            data: {
                exonerated?: boolean;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/exonerated-vat`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Generate order(s) from cart : one order if the cart has only products with shop stock, two if shop & web stock
         *
         * @tags oms-private, cart, retail
         * @name GetOrdersFromCart
         * @summary Get orders from cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/orders
         * @secure
         */
        getOrdersFromCart: (uid: string, params: RequestParams = {}) =>
            this.request<
                {
                    /** reference */
                    number?: string;
                    userId?: string;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    invoiceLink?: string | null;
                    /** Total number of products in the order (include LMS) */
                    totalProductQuantity?: number;
                    /**
                     * Number of products for last minute shopping
                     * @default 0
                     */
                    lastMinuteQuantity?: number;
                    /**
                     * Number of products for simple delivery
                     * @default 0
                     */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents, without order discount */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency, without order discount */
                    subTotalDisplayPrice?: string;
                    /** display or hide prices on order */
                    displayPrices?: boolean;
                    /** order returnable? */
                    isReturnable?: boolean;
                    /** order cancelable? */
                    isCancelable?: boolean;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents (order discount included) */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency (order discount included) */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    /** External id from international service (globalE ; only for Order) */
                    externalId?: string | null;
                    shipping?: Shipping;
                    addresses?: {
                        shipping?: Address;
                        billing?: Address;
                    };
                    /** @default [] */
                    mergedOrderReferences?: number[];
                    orderReferenceBarcodePath?: string | null;
                    /** order status */
                    status?:
                        | 'created'
                        | 'pending'
                        | 'to_validate'
                        | 'validated'
                        | 'sent_to_logistic'
                        | 'integrated'
                        | 'in_preparation'
                        | 'packed'
                        | 'shipped'
                        | 'canceled'
                        | 'delivered'
                        | 'payment_failed'
                        | 'delivered_to_customer'
                        | null;
                    /** Payment status */
                    paymentStatus?: 'waiting' | 'failed' | 'canceled' | 'validated' | null;
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                    /** @default false */
                    isPoshmarkResellable?: boolean | null;
                    /** @default false */
                    hasReturnLabel?: boolean | null;
                }[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/orders`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * @description This will remove all orders related to a cart with a status CREATED or PENDING.
         *
         * @tags oms-private, cart, retail
         * @name DeleteRetailCartOrders
         * @summary Delete orders of a retail cart
         * @request DELETE:/oms-private/v1/retail/cart/{uid}/orders
         * @secure
         */
        deleteRetailCartOrders: (uid: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/orders`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * @description Mark order(s) related from a retail cart as paid
         *
         * @tags oms-private, cart, retail
         * @name CartMarkAsPaid
         * @summary Mark order(s) from a retail cart as paid
         * @request POST:/oms-private/v1/retail/cart/{uid}/mark-as-paid
         * @secure
         */
        cartMarkAsPaid: (
            uid: string,
            data?: {
                paymentMethod?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderRead[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/mark-as-paid`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Add a payment on a retail cart with amount and payment method
         *
         * @tags oms-private, cart, retail
         * @name AddPaymentOnRetailCart
         * @summary Add a payment on a retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/add-payment
         * @secure
         */
        addPaymentOnRetailCart: (
            uid: string,
            data: {
                amountPaid?: number | null;
                paymentMethod?: 'cash' | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    data?: {
                        remainingAmountToPay?: number;
                        orders?: OrderRead[];
                    };
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/add-payment`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name AddGiftMessageOnRetailCart
         * @summary Add a gift message and a title to a retail cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/gift-message
         * @secure
         */
        addGiftMessageOnRetailCart: (uid: string, data: GiftMessage, params: RequestParams = {}) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/gift-message`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, retail
         * @name GetInvoiceForRetail
         * @summary Get invoice PDF for a given order
         * @request GET:/oms-private/v1/retail/invoice/{orderReference}
         * @secure
         */
        getInvoiceForRetail: (
            orderReference: number,
            query?: {
                /** Invoice type: client (default) or log */
                type?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                File,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/invoice/${orderReference}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name RetailUserList
         * @summary Get users list
         * @request GET:/oms-private/v1/retail/users
         * @secure
         */
        retailUserList: (
            query?: {
                /** search on email or first/last name */
                search?: string;
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
                order?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                RetailUserList[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/users`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name CreateRetailUser
         * @summary Create a user via the retail app
         * @request POST:/oms-private/v1/retail/users
         */
        createRetailUser: (
            data: RetailUserCreate,
            query?: {
                /**
                 * Uid of the shop where the order is placed. Prevails over the shopId when given.
                 * @format uuid
                 * @example "19b84154-218c-469c-a49a-bc1d2fccc16c"
                 */
                shopUid?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                RetailUserRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/users`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name RetailUserRead
         * @summary Get user info
         * @request GET:/oms-private/v1/retail/users/{keycloakId}
         * @secure
         */
        retailUserRead: (
            keycloakId: string,
            query: {
                brandCode: string;
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                RetailUserRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name RetailUserByIdRead
         * @summary Get user by ID info
         * @request GET:/oms-private/v1/retail/users-by-id/{id}
         * @secure
         */
        retailUserByIdRead: (
            id: string,
            query: {
                brandCode: string;
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                RetailUserRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/users-by-id/${id}`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name RetailUserUpdate
         * @summary Update user
         * @request PUT:/oms-private/v1/retail/users-by-id/{id}
         * @secure
         */
        retailUserUpdate: (
            id: string,
            query: {
                brandCode: string;
                locale: string;
            },
            data: RetailUserWrite,
            params: RequestParams = {}
        ) =>
            this.request<
                RetailUserRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/users-by-id/${id}`,
                method: 'PUT',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, user-wishlist
         * @name GetWishlistItems
         * @summary Get paginated user wishlists
         * @request GET:/oms-private/v1/retail/users/{keycloakId}/wishlist
         * @secure
         */
        getWishlistItems: (
            keycloakId: string,
            query: {
                brandCode: string;
                /** @default 1 */
                page?: number;
                /**
                 * Indicate number of items to retrieve
                 * @default 12
                 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    id?: number;
                    uid?: string;
                    imageUrl?: string | null;
                    link?: string | null;
                    ean?: string;
                    /** @format date-time */
                    createdAt?: string | null;
                    name?: string | null;
                    displayPrice?: string | null;
                    price?: ProductPrice;
                    displayCrossedOutPrice?: string | null;
                    crossedOutPrice?: ProductPrice;
                    status?: ProductStatus;
                    color?: string | null;
                    lmsAvailable?: boolean | null;
                    available?: boolean | null;
                    /** @default false */
                    webStockAvailable?: boolean;
                    size?: string | null;
                    sizes?: {
                        price?: ProductPrice;
                        status?: ProductStatus;
                        uuid?: string | null;
                    }[];
                    tracking?: {
                        brand?: string | null;
                        category?: {
                            label?: string | null;
                        };
                        product?: {
                            code?: string | null;
                            uuid?: string | null;
                        };
                    };
                }[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/wishlist`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, retail, user, user-alert
         * @name GetRetailAlerts
         * @summary Get paginated user alerts
         * @request GET:/oms-private/v1/retail/users/{keycloakId}/alerts
         * @secure
         */
        getRetailAlerts: (
            keycloakId: string,
            query: {
                brandCode: string;
                /** @default 1 */
                page?: number;
                /**
                 * Indicate number of items to retrieve
                 * @default 12
                 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    id?: number;
                    uid?: string;
                    imageUrl?: string | null;
                    link?: string | null;
                    ean?: string;
                    /** @format date-time */
                    createdAt?: string | null;
                    name?: string | null;
                    displayPrice?: string | null;
                    price?: ProductPrice;
                    displayCrossedOutPrice?: string | null;
                    crossedOutPrice?: ProductPrice;
                    status?: ProductStatus;
                    color?: string | null;
                    lmsAvailable?: boolean | null;
                    available?: boolean | null;
                    /** @default false */
                    webStockAvailable?: boolean;
                    size?: string | null;
                    sizes?: {
                        price?: ProductPrice;
                        status?: ProductStatus;
                        uuid?: string | null;
                    }[];
                    tracking?: {
                        brand?: string | null;
                        category?: {
                            label?: string | null;
                        };
                        product?: {
                            code?: string | null;
                            uuid?: string | null;
                        };
                    };
                }[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/alerts`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name RetailAddAddress
         * @summary Add a user address
         * @request POST:/oms-private/v1/retail/users/{keycloakId}/addresses
         * @secure
         */
        retailAddAddress: (
            keycloakId: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                Address,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/addresses`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name RetailUpdateAddress
         * @summary Update a user address
         * @request PUT:/oms-private/v1/retail/users/{keycloakId}/addresses/{uid}
         * @secure
         */
        retailUpdateAddress: (
            keycloakId: string,
            uid: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                Address,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/addresses/${uid}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user, retail
         * @name RetailRemoveAddress
         * @summary Remove a user address
         * @request DELETE:/oms-private/v1/retail/users/{keycloakId}/addresses/{uid}
         * @secure
         */
        retailRemoveAddress: (keycloakId: string, uid: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/addresses/${uid}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, retail, user-credits
         * @name RetailUserCreditsList
         * @summary Get paginated users credits list
         * @request GET:/oms-private/v1/retail/users/{keycloakId}/credits
         * @secure
         */
        retailUserCreditsList: (
            keycloakId: string,
            query?: {
                /** @default 1 */
                page?: number;
                /** @default 30 */
                itemsPerPage?: number;
                order?: string[];
            },
            params: RequestParams = {}
        ) =>
            this.request<
                UserCredit[],
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/credits`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, retail, user-credits
         * @name RetailUserCreditsCreate
         * @summary Create a new user credit
         * @request POST:/oms-private/v1/retail/users/{keycloakId}/credits
         */
        retailUserCreditsCreate: (keycloakId: string, data: UserCredit, params: RequestParams = {}) =>
            this.request<
                UserCredit[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/credits`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name GetUserOrders
         * @summary Get orders for user
         * @request GET:/oms-private/v1/retail/users/{keycloakId}/orders
         * @secure
         */
        getUserOrders: (
            keycloakId: string,
            query?: {
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                RetailOrderList,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/users/${keycloakId}/orders`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name GetRetailOrder
         * @summary get order
         * @request GET:/oms-private/v1/retail/order/{orderReference}
         * @secure
         */
        getRetailOrder: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead & OrderReadReturnsList,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags order, order-return
         * @name RetailGetReturnLabel
         * @summary Get the pdf file of the order return label
         * @request GET:/oms-private/v1/retail/order/{orderReference}/return-label
         * @secure
         */
        retailGetReturnLabel: (orderReference: string, params: RequestParams = {}) =>
            this.request<
                File,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}/return-label`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name CancelLmsDeliveryJob
         * @summary Cancel an LMS delivery job on Stuart
         * @request DELETE:/oms-private/v1/retail/order/{orderReference}/last_minute
         * @secure
         */
        cancelLmsDeliveryJob: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}/last_minute`,
                method: 'DELETE',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name CreateLmsDeliveryJob
         * @summary Create a new LMS delivery job on Stuart
         * @request POST:/oms-private/v1/retail/order/{orderReference}/last_minute
         * @secure
         */
        createLmsDeliveryJob: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}/last_minute`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name PutRetailOrderStatusToDeliverCustomer
         * @summary Update order status to deliverCustomer
         * @request PUT:/oms-private/v1/retail/order/{orderReference}/deliver_customer
         * @secure
         */
        putRetailOrderStatusToDeliverCustomer: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}/deliver_customer`,
                method: 'PUT',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name PutRetailOrderStatusToShipped
         * @summary Update retail order to shipped status
         * @request PUT:/oms-private/v1/retail/order/{orderReference}/shipped
         * @secure
         */
        putRetailOrderStatusToShipped: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}/shipped`,
                method: 'PUT',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name PutRetailLmsOrderStatus
         * @summary Update LMS order from validated status to in_preparation
         * @request PUT:/oms-private/v1/retail/order/{orderReference}/in_preparation
         * @secure
         */
        putRetailLmsOrderStatus: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}/in_preparation`,
                method: 'PUT',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name CancelRetailLmsOrder
         * @summary Cancel LMS order. Status from from pending, to_validate or validated status to canceled
         * @request PUT:/oms-private/v1/retail/order/{orderReference}/cancel_last_minute
         * @secure
         */
        cancelRetailLmsOrder: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${orderReference}/cancel_last_minute`,
                method: 'PUT',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name RetailListOrderReturns
         * @summary Get order returns linked to the given order that can be processed in a shop, i.e. those in 'pending' status with no transporter hand-over date
         * @request GET:/oms-private/v1/retail/order/{reference}/return
         * @secure
         */
        retailListOrderReturns: (
            reference: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnListElement[],
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${reference}/return`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name RetailCreateOrderReturn
         * @summary Create a new order return
         * @request POST:/oms-private/v1/retail/order/{reference}/return
         * @secure
         */
        retailCreateOrderReturn: (
            reference: string,
            query: {
                locale: string;
            },
            data: OrderReturnCreatePayload,
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/order/${reference}/return`,
                method: 'POST',
                query: query,
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name RetailComputeOrderRefundAmount
         * @summary Compute order refund amount
         * @request POST:/oms-private/v1/retail/order/{reference}/refund/amount
         */
        retailComputeOrderRefundAmount: (reference: string, data: OrderRefundBody, params: RequestParams = {}) =>
            this.request<
                OrderRefundAmount,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/${reference}/refund/amount`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name ListLmsOrders
         * @summary List all validated LMS orders
         * @request GET:/oms-private/v1/retail/order/last_minute
         * @secure
         */
        listLmsOrders: (orderReference: number, params: RequestParams = {}) =>
            this.request<
                OrderList[],
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order/last_minute`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags staff, retail
         * @name RetailStaffAddAttribute
         * @summary Add a valid attribute to staff user. The only accepted attribute for now is "pin_cashier"
         * @request POST:/oms-private/v1/retail/cashier/attributes
         * @secure
         */
        retailStaffAddAttribute: (data: AdminAddAttribute, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cashier/attributes`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, retail, order
         * @name RetailCashierCartsList
         * @summary Get cashier carts list in a specific shop
         * @request GET:/oms-private/v1/retail/shop/{uid}/cashier/carts
         * @secure
         */
        retailCashierCartsList: (
            uid: string,
            query?: {
                /** @default 1 */
                page?: number;
                /** @default 50 */
                itemsPerPage?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                }[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/shop/${uid}/cashier/carts`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, retail, user-credits
         * @name DeleteRetailUserCredit
         * @summary Remove a retail user credit from cart
         * @request DELETE:/oms-private/v1/retail/cart/{uid}/user-credit/{userCreditUid}
         * @secure
         */
        deleteRetailUserCredit: (uid: string, userCreditUid: string, params: RequestParams = {}) =>
            this.request<
                {
                    /**
                     * id encrypted
                     * @format uuid
                     */
                    number?: string;
                    userId?: string | null;
                    userFullName?: string | null;
                    userEmail?: string | null;
                    /**
                     * Creation date
                     * @format date-time
                     */
                    createdAt?: string;
                    /**
                     * Last update date
                     * @format date-time
                     */
                    updatedAt?: string;
                    /**
                     * Expiration date for a cart
                     * @format date-time
                     */
                    expireAt?: string;
                    /** Total number of products in the order */
                    totalProductQuantity?: number;
                    /** Number of products for last minute shopping */
                    lastMinuteQuantity?: number;
                    /** Number of products for simple delivery */
                    simpleDeliveryQuantity?: number;
                    /** Number of products from retail */
                    retailQuantity?: number;
                    /** Amount tax included for all the articles, in cents */
                    subTotalPrice?: number;
                    /** Total VAT for all the articles, in cents */
                    subTotalPriceVat?: number;
                    /** Amount tax included for all the articles, with currency */
                    subTotalDisplayPrice?: string;
                    /** Amount tax included for the shipping, in cents */
                    shippingPrice?: number;
                    /** Total VAT for the shipping, in cents */
                    shippingPriceVat?: number;
                    /** Amount tax included for the shipping, with currency */
                    shippingDisplayPrice?: string;
                    /** Amount tax included of the discount, in cents */
                    discountPrice?: number;
                    /** Amount tax included of the discount, with currency */
                    discountDisplayPrice?: string;
                    /** Total vat, in cents */
                    totalVat?: number;
                    /** Total vat, with currency */
                    totalVatDisplayed?: string;
                    /** Total price tax included without shipping, in cents */
                    totalPriceWithoutShipping?: number;
                    /** Total price tax included without shipping, with currency */
                    totalDisplayPriceWithoutShipping?: string;
                    /** Total price tax included, in cents */
                    totalPrice?: number;
                    /** Total price tax included, in minor units (eg. 1000¥  => 1000 and not 100000) */
                    minorUnitsTotalPrice?: number;
                    /** Total price tax included, with currency */
                    totalDisplayPrice?: string;
                    /** Amount sales tax, in cents */
                    amountSalesTax?: number;
                    /** Amount sales tax, with currency */
                    amountSalesTaxDisplay?: string;
                    /** Amount duties and other fees, in cents */
                    amountDutiesAndOtherFees?: number;
                    /** Amount duties and other fees, with currency */
                    amountDutiesAndOtherFeesDisplay?: string;
                    /** Amount duties and other fees threshold, in cents */
                    amountDutiesAndOtherFeesThreshold?: number | null;
                    /** Amount duties and other fees threshold, with currency */
                    amountDutiesAndOtherFeesThresholdDisplay?: string | null;
                    currencyCode?: string | null;
                    products?: any[];
                    vouchers?: any[];
                    userCredits?: any[];
                    ecards?: any[];
                    packaging?: PackagingProduct[];
                    addresses?: CartAddresses;
                    giftMessage?: GiftMessage;
                    lastMinuteShoppingMode?: ShippingMode;
                    shippingMode?: ShippingMode;
                    shippingCountryCode?: string | null;
                    shippingStateCode?: string | null;
                    /** @default true */
                    needAddress?: boolean;
                    /** @default true */
                    enableGiftMessage?: boolean;
                    /** Commercial operation with code applied to the cart. (Had kept the old name) */
                    promotionalCampaigns?: {
                        id?: number;
                        uid?: string;
                        /** Code of the commercial operation */
                        code?: string;
                    }[];
                    localeCurrencyCode?: string | null;
                    localeSubTotalPrice?: number | null;
                    localeSubTotalDisplayPrice?: string | null;
                    localeTotalPriceWithoutShipping?: number | null;
                    localeTotalDisplayPriceWithoutShipping?: string | null;
                    localeTotalPrice?: number | null;
                    localeTotalDisplayPrice?: string | null;
                    localeShippingPrice?: number | null;
                    localeShippingDisplayPrice?: string | null;
                    localeTotalVat?: number | null;
                    localeTotalVatDisplayed?: string | null;
                    localeAmountSalesTax?: number | null;
                    localeAmountSalesTaxDisplay?: string | null;
                    localeAmountDutiesAndOtherFees?: number | null;
                    localeAmountDutiesAndOtherFeesDisplay?: string | null;
                    localeDiscountPrice?: number | null;
                    localeDiscountDisplayPrice?: string | null;
                    refundHoldAmount?: number | null;
                    /** The total refund hold amount, in order currency */
                    refundHoldAmountDisplayPrice?: string | null;
                },
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/cart/${uid}/user-credit/${userCreditUid}`,
                method: 'DELETE',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, rack, oms
         * @name CreateRack
         * @summary Create a new retail rack
         * @request POST:/oms-private/v1/retail/shop/{id}/rack
         */
        createRack: (id: string, data: Rack, params: RequestParams = {}) =>
            this.request<
                Rack,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/shop/${id}/rack`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, rack, oms
         * @name ListRackToRestock
         * @summary list of retail rack to restock
         * @request GET:/oms-private/v1/retail/shop/{id}/rack
         */
        listRackToRestock: (id: string, params: RequestParams = {}) =>
            this.request<
                Rack[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/shop/${id}/rack`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, rack, oms
         * @name RackRestock
         * @summary Restock rack
         * @request PUT:/oms-private/v1/retail/shop/{id}/rack/restock
         */
        rackRestock: (id: string, data: Rack[], params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/shop/${id}/rack/restock`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description This route is used by app to make a payment session request to Adyen
         *
         * @tags shop, payment, oms
         * @name MakePaymentSessionRequest
         * @summary Make a payment session request to Adyen
         * @request POST:/oms-private/v1/retail/shop/{uid}/pos/payment/sessions
         */
        makePaymentSessionRequest: (uid: string, data: CartPaymentSessionRequest, params: RequestParams = {}) =>
            this.request<
                CartPaymentSessionResponse,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/shop/${uid}/pos/payment/sessions`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description This route is used to send test push notification request to Batch
         *
         * @tags notification, oms
         * @name SendTestNotification
         * @summary Send a test notification
         * @request POST:/oms-private/v1/retail/lms/test-notifications
         */
        sendTestNotification: (data: NotificationRequest, params: RequestParams = {}) =>
            this.request<
                NotificationResponse,
                | NotificationResponseError
                | {
                      type: string;
                      title: string;
                      status: number;
                      detail: string;
                      'invalid-params': InvalidParams;
                  }
            >({
                path: `/oms-private/v1/retail/lms/test-notifications`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail
         * @name GetStocks
         * @summary Get stocks for a list of products
         * @request POST:/oms-private/v1/retail/stocks
         */
        getStocks: (
            data: GetStockRequest,
            query?: {
                /**
                 * Currency code ISO 4217
                 * @default "EUR"
                 */
                currencyCode?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<GetStockResponse[], any>({
                path: `/oms-private/v1/retail/stocks`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, order-return
         * @name RetailGetOrderReturn
         * @summary Retail endpoint to get order return detail
         * @request GET:/oms-private/v1/retail/order-returns/{uid}
         * @secure
         */
        retailGetOrderReturn: (
            uid: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                void | {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order-returns/${uid}`,
                method: 'GET',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, order-return
         * @name RetailUpdateOrderReturn
         * @summary Update order return (only works when status = 'pending' and transporter hand-over date is null)
         * @request PUT:/oms-private/v1/retail/order-returns/{uid}
         * @secure
         */
        retailUpdateOrderReturn: (
            uid: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order-returns/${uid}`,
                method: 'PUT',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, order-return
         * @name RetailOrderReturnCancel
         * @summary Cancel order return
         * @request PUT:/oms-private/v1/retail/order-returns/{uid}/cancel
         * @secure
         */
        retailOrderReturnCancel: (
            uid: string,
            query: {
                locale: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/retail/order-returns/${uid}/cancel`,
                method: 'PUT',
                query: query,
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, order-return
         * @name RetailProcessOrderReturn
         * @summary Retail endpoint to manually process order return and refund the items using the provided quantities
         * @request PUT:/oms-private/v1/retail/order-returns/{uid}/process
         */
        retailProcessOrderReturn: (
            uid: string,
            query: {
                locale: string;
            },
            data: ProcessOrderReturnInput,
            params: RequestParams = {}
        ) =>
            this.request<
                OrderReturnDetail,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/order-returns/${uid}/process`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, retail, nf525
         * @name RetailUnavailablePrinter
         * @summary Retail endpoint to notify that the printer is unavailable
         * @request POST:/oms-private/v1/retail/shop/{uid}/unavailable-printer
         */
        retailUnavailablePrinter: (uid: string, data: object, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/oms-private/v1/retail/shop/${uid}/unavailable-printer`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, retail, nf525
         * @name RetailProcessingCashDrawer
         * @summary Retail endpoint to notify that the cash drawer is being processed
         * @request POST:/oms-private/v1/retail/shop/{uid}/cash-drawer
         */
        retailProcessingCashDrawer: (uid: string, data: object, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/oms-private/v1/retail/shop/${uid}/cash-drawer`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail
         * @name CloseCashier
         * @summary Signals a cashier has closed
         * @request POST:/oms-private/v1/retail/shop/{uid}/cashier-close
         */
        closeCashier: (uid: string, data: CashierClose, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/shop/${uid}/cashier-close`,
                method: 'POST',
                body: data,
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail
         * @name CreateSession
         * @summary Signals a new session has started
         * @request POST:/oms-private/v1/retail/shop/{uid}/session
         */
        createSession: (uid: string, data: SessionCreate, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/shop/${uid}/session`,
                method: 'POST',
                body: data,
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail
         * @name DestroySession
         * @summary Signals a new session has been destroyed
         * @request DELETE:/oms-private/v1/retail/shop/{uid}/session
         */
        destroySession: (uid: string, data: SessionDestroy, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/shop/${uid}/session`,
                method: 'DELETE',
                body: data,
                ...params,
            }),

        /**
         * No description
         *
         * @tags retail, nf525
         * @name GenerateReceipts
         * @summary Generates and returns the receipts for a given Cart
         * @request POST:/oms-private/v1/retail/cart/{uid}/paid
         */
        generateReceipts: (uid: string, data: CartPaymentValidate, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/retail/cart/${uid}/paid`,
                method: 'POST',
                body: data,
                ...params,
            }),

        /**
         * No description
         *
         * @tags commercial-operation, oms-private
         * @name CreateCommercialOperation
         * @summary Create a new commercial operation
         * @request POST:/oms-private/v1/commercial-operation
         */
        createCommercialOperation: (data: CommercialOperation, params: RequestParams = {}) =>
            this.request<
                CommercialOperation,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/commercial-operation`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags commercial-operation, oms-private
         * @name ListCommercialOperations
         * @summary list all commercial operations
         * @request GET:/oms-private/v1/commercial-operation
         */
        listCommercialOperations: (
            query?: {
                /** Filter by status. Ex : 'enabled' => 'true' */
                enabled?: boolean;
                /** Display soft deleted operations. Ex : 'showDeleted' => 'true' */
                showDeleted?: boolean;
                /** Filter on brand code (sezane, octobre) */
                brands?: string[];
                /** Filter by sites, 'sites[0]' => 'fr' 'sites[1]' => 'uk' */
                sites?: string[];
                /** Filter on countries (FR, ES, BL) */
                countries?: string[];
                /** Filter by id, 'id[]' => 2 */
                id?: string[];
                /** search by name */
                search?: string;
                /** Filter on offered products ean 'offeredProducts[]' => '123'. */
                offeredProducts?: string[];
                /** Filter on usage total count 'usageTotalCount[]' => 3. */
                usageTotalCount?: number[];
                /** Order by, for example 'order[usageTotalCount]' => 'ASC', 'order[name.raw]' => 'DESC', 'order[id]' => 'ASC', 'order[startAt]' => 'DESC' */
                order?: string[];
                /** Filter on creation date */
                createdAt?: string[];
                /** @default 1 */
                page?: number;
                /** @default 10 */
                itemsPerPage?: number;
                /** Commercial operation ids separated by ',' to add to the list */
                suppIds?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CommercialOperation[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/commercial-operation`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags commercial-operation, oms-private
         * @name GetCommercialOperation
         * @summary Get a  commercial operation
         * @request GET:/oms-private/v1/commercial-operation/{uid}
         */
        getCommercialOperation: (uid: any, params: RequestParams = {}) =>
            this.request<
                CommercialOperation,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/commercial-operation/${uid}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags commercial-operation, oms-private
         * @name PutCommercialOperation
         * @summary Update a commercial operation
         * @request PUT:/oms-private/v1/commercial-operation/{uid}
         */
        putCommercialOperation: (uid: any, data: CommercialOperation, params: RequestParams = {}) =>
            this.request<
                CommercialOperation,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/commercial-operation/${uid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags commercial-operation, oms-private
         * @name DeleteCommercialOperation
         * @summary Delete a commercial operation
         * @request DELETE:/oms-private/v1/commercial-operation/{uid}
         */
        deleteCommercialOperation: (uid: any, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-private/v1/commercial-operation/${uid}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name CreateCartFromOrder
         * @summary Generate a new cart from an order
         * @request POST:/oms-private/v1/cart/from-order/{reference}
         */
        createCartFromOrder: (reference: number, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/from-order/${reference}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name ExpiredCustomerServiceCart
         * @summary Expired cart
         * @request DELETE:/oms-private/v1/cart/{uid}
         */
        expiredCustomerServiceCart: (uid: string, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}`,
                method: 'DELETE',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name AddGiftMessageOnCustomerServiceCart
         * @summary Add a gift message and a title to a customer service cart
         * @request POST:/oms-private/v1/cart/{uid}/gift-message
         */
        addGiftMessageOnCustomerServiceCart: (uid: string, data: GiftMessage, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/gift-message`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name DeleteGiftMessageOnCustomerServiceCart
         * @summary Delete a gift message from a customer service cart
         * @request DELETE:/oms-private/v1/cart/{uid}/gift-message
         */
        deleteGiftMessageOnCustomerServiceCart: (uid: string, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/gift-message`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name AddShippingOnCustomerServiceCart
         * @summary Set the shipping mode on a reorder cart
         * @request POST:/oms-private/v1/cart/{uid}/shipping/{shippingModeUid}
         */
        addShippingOnCustomerServiceCart: (uid: string, shippingModeUid: string, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/shipping/${shippingModeUid}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name AddShippingAddressOnCustomerServiceCart
         * @summary Add the shipping address on cart
         * @request POST:/oms-private/v1/cart/{uid}/shipping/{shippingModeUid}/address
         */
        addShippingAddressOnCustomerServiceCart: (
            uid: string,
            shippingModeUid: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/shipping/${shippingModeUid}/address`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Returns the eligible delivery methods for the cart. There may be restrictions depending on product, location, etc.
         *
         * @tags oms-private, cart, reorder
         * @name GetCustomerServiceCartShippingModes
         * @summary Returns the eligible delivery methods
         * @request GET:/oms-private/v1/cart/{uid}/shipping-modes
         */
        getCustomerServiceCartShippingModes: (uid: string, params: RequestParams = {}) =>
            this.request<
                ShippingMode[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/shipping-modes`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name AddBillingAddressOnCustomerServiceCart
         * @summary Add the billing address on a SC cart
         * @request POST:/oms-private/v1/cart/{uid}/billing-address
         */
        addBillingAddressOnCustomerServiceCart: (
            uid: string,
            data: {
                /** @format uuid */
                uid?: string | null;
                /** For the moment, key used by the Checkout during the render of the addresses listing */
                id?: number | null;
                /**
                 * A flag to know if the address is the default one for the user
                 * @default false
                 */
                isDefault?: boolean;
                address?: Address;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/billing-address`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, order, reorder
         * @name GetOrderToReorder
         * @summary Get order
         * @request GET:/oms-private/v1/order/{reference}
         */
        getOrderToReorder: (reference: number, params: RequestParams = {}) =>
            this.request<
                OrderRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/order/${reference}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name ReorderAddProduct
         * @summary Add an entry in the cart
         * @request POST:/oms-private/v1/cart/{uid}/product
         */
        reorderAddProduct: (uid: string, data: AddProductRequestBody, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/product`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name ReorderDeleteProduct
         * @summary Delete an entry in the cart
         * @request DELETE:/oms-private/v1/cart/{uid}/product/{lineItemUid}
         */
        reorderDeleteProduct: (uid: string, lineItemUid: string, params: RequestParams = {}) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/product/${lineItemUid}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, cart, reorder
         * @name ReorderUpdateProduct
         * @summary Update an entry in the cart
         * @request PUT:/oms-private/v1/cart/{uid}/product/{lineItemUid}
         */
        reorderUpdateProduct: (
            uid: string,
            lineItemUid: string,
            data: {
                quantity?: number | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                CartRead,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/product/${lineItemUid}`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * @description Generate order(s) from cart
         *
         * @tags oms-private, cart, reorder
         * @name GetOrdersFromCustomerServiceCart
         * @summary Get orders from cart
         * @request POST:/oms-private/v1/cart/{uid}/orders
         */
        getOrdersFromCustomerServiceCart: (uid: string, params: RequestParams = {}) =>
            this.request<
                OrderRead[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                } | void
            >({
                path: `/oms-private/v1/cart/${uid}/orders`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * @description Get references list
         *
         * @tags oms-private, references
         * @name GetReferencesListRestricted
         * @summary Get references list
         * @request GET:/oms-private/v1/references
         */
        getReferencesListRestricted: (
            query?: {
                siteCode?: string;
                currencyCode?: string;
                brandCode?: string;
                localeCode?: string | null;
            },
            params: RequestParams = {}
        ) =>
            this.request<References, any>({
                path: `/oms-private/v1/references`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    };
    omsInternal = {
        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShopsInternal
         * @summary Get all shops
         * @request GET:/oms-internal/v1/shop/
         */
        getShopsInternal: (
            query?: {
                /** Get only enabled shops (value should be equal to "1") */
                enabled?: boolean;
                /**
                 * Search by description
                 * @default ""
                 */
                description?: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<
                Shop[],
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-internal/v1/shop/`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms, shipping
         * @name GetShopInternal
         * @summary Get a shop
         * @request GET:/oms-internal/v1/shop/{id}
         */
        getShopInternal: (id: string, params: RequestParams = {}) =>
            this.request<
                Shop,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-internal/v1/shop/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags oms-private, infrastructure
         * @name InfrastructureJournalCreateDatabaseBackup
         * @summary Notify the infrastructure created a new database backup
         * @request POST:/oms-internal/v1/infrastructure/database-backup
         */
        infrastructureJournalCreateDatabaseBackup: (data: DatabaseBackupCreate, params: RequestParams = {}) =>
            this.request<
                void,
                {
                    type: string;
                    title: string;
                    status: number;
                    detail: string;
                    'invalid-params': InvalidParams;
                }
            >({
                path: `/oms-internal/v1/infrastructure/database-backup`,
                method: 'POST',
                body: data,
                ...params,
            }),
    };
    pimPrivate = {
        /**
         * No description
         *
         * @name GetCrons
         * @summary Get PIM scheduled tasks.
         * @request GET:/pim-private/v1/crons
         */
        getCrons: (params: RequestParams = {}) =>
            this.request<ScheduledTask[], void>({
                path: `/pim-private/v1/crons`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name UpdateCrons
         * @summary Update PIM scheduled tasks.
         * @request PUT:/pim-private/v1/crons
         */
        updateCrons: (data: ScheduledTask[], params: RequestParams = {}) =>
            this.request<ScheduledTask[], void>({
                path: `/pim-private/v1/crons`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @name DeleteCron
         * @summary Delete PIM scheduled task.
         * @request DELETE:/pim-private/v1/crons/{name}
         */
        deleteCron: (name: string, params: RequestParams = {}) =>
            this.request<ScheduledTask[], void>({
                path: `/pim-private/v1/crons/${name}`,
                method: 'DELETE',
                format: 'json',
                ...params,
            }),
    };
}
